import Header from '../components/header';

export default function Gallery(){
  return(
    <>
      <Header />
      <h2>Gallery Page</h2>
    </>
  )
}
