import Header from '../components/header';
import Footer from '../components/footer';

export default function Videos() {
    return (
        <>
            <Header />
            <img className='srimg' src="vid.jpg" width="100%" style={{ borderRadius: 20 }} />
            <section
                className="cc"
                style={{
                    background: "url(dfddfvbds.png)",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }}
            >
                <div className="container">

                    <div className="row">
                        <div className='col-md-12'>
                            <h3 style={{ color: "#2e1b1d" }}>Staff Selection Commission (SSC)</h3>
                            <hr style={{ border: "1px solid #f27e18" }} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gXhdno374Og?si=BzoVmxPYExzFGxR7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/-1einz1-8OU?si=h0Dt10N3JRgscYMa" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/fdDa2jeGx5g?si=lQIWEcRhIKKelEIY" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h3 style={{ color: "#2e1b1d" }}>Railway Recruitment Board</h3>
                            <hr style={{ border: "1px solid #f27e18" }} />
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/8fXtK8O8YeA?si=_bHmmzikY3C_GwB2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/5Gy7LAG7uV8?si=FYC_lP1NEn8r1yIM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/nBjdUJlRZUc?si=B3qqjiiZi1c28UzM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h3 style={{ color: "#2e1b1d" }}>Defence </h3>
                            <hr style={{ border: "1px solid #f27e18" }} />
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/7y_tlPQFtk0?si=-gOwo4tWlXTVgX4Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CZyRrNgb6qU?si=yPDmN229QhOJDcW7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CvjcN6ErK34?si=bIAIrA00iZnoYhL2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h3 style={{ color: "#2e1b1d" }}>Bank PO</h3>
                            <hr style={{ border: "1px solid #f27e18" }} />
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/BGN5IUSLk_c?si=4ObMw-AgAFv15hcR" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gpA7BpubhdU?si=CTFTlQGbGnPvc8VW" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/EpTupTIKRCM?si=fbDh6oDaf3isBA1w" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h3 style={{ color: "#2e1b1d" }}>All Other Exams</h3>
                            <hr style={{ border: "1px solid #f27e18" }} />
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/xQyE0Qzz6XE?si=DebtTLR-6PBN37FG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/zNPKQjAFsvc?si=9S4pTuEs85dzrLmz" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                                <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/KO3gtJh9XV8?si=2HmWP-2bINCFz8zm" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </section>






















            <Footer />
        </>
    )
}