
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink ,useParams} from 'react-router-dom';                                                                                                                                                                                                                                                                                                   

import img11 from "../../images/11.png"
import img22 from "../../images/22.png"
import imgcopy22 from "../../images/22Copy.png"
const Category = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Fetch categories when the component mounts
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                //const response = await fetch(`http://192.168.0.102:1038/user/category`);
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/category`);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                // Update the state with fetched categories
                setCategories(data.category);

                // Set the selected category to the ID of the first category
                if (data.category.length > 0) {
                    setSelectedCategory(data.category[0]._id);
                    fetchSubCategories(data.category[0]._id);
                }
            } catch (error) {
                console.error(error);
                // Handle errors, e.g., display an error message
            }
        };

        fetchCategories();
    }, []);

    // Fetch subcategories for the selected category
    useEffect(() => {
        if (selectedCategory) {
            // try {
            //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/sub_category?category_id=${selectedCategory}`);
            //     if (!response.ok) {
            //         throw new Error('Failed to fetch subcategories');
            //     }
            //     const data = await response.json();
            //     setSubCategories(data.sub_category);
            // } catch (error) {
            //     console.error(error);
            //     // Handle errors, e.g., display an error message
            // }
            ;
        }
    }, [selectedCategory]);

    const fetchSubCategories = async (selectedCategory) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/sub_category?category_id=${selectedCategory}`);
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();
            setSubCategories(data.sub_category);
        } catch (error) {
            console.error(error);
            // Handle errors, e.g., display an error message
        }
    };

    const handleCategorySelect = async (categoryId) => {
        try {
            fetchSubCategories(categoryId);
            const authToken = localStorage.getItem('token');
            
            // Check if authToken exists
            if (!authToken) {
                // Handle case where authToken is not available
                throw new Error('Authentication token not found');
            }

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/select_category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    category_id: categoryId
                })
            });
            if (!response.ok) {
                throw new Error('Failed to select category');
            }

            // Set the selected category
            setSelectedCategory(categoryId);
            
            
            console.log("Selected category ID:", categoryId);
             
           
        } catch (error) {
            console.error(error);
            // Handle errors, e.g., display an error message
        }
    };

    // Handle subcategory selection
    const handleSubCategorySelect = async (subCategoryId) => {
          navigate(`/testserieslist/${subCategoryId}`);
        // try {
        //     // const authToken = localStorage.getItem('token');
        //     // Check if authToken exists
        //     // if (!authToken) {
        //     //     // Handle case where authToken is not available
        //     //     throw new Error('Authentication token not found');
        //     // }
        //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/select_sub_category`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             // 'Authorization': `Bearer ${authToken}`
        //         },
        //         body: JSON.stringify({
        //             sub_category_id: subCategoryId
        //         })
        //     });
        //   console.log(response);
        //     if (!response.ok) {
        //         throw new Error('Failed to select subcategory');
        //     }
        //     // Set the selected subcategory
        //     setSelectedSubCategory(subCategoryId);
        //     console.log("Selected subcategory ID:", subCategoryId);
        //     // navigate(`/testseries/${subCategoryId}`);
        //     navigate(`/testseries_details/${subCategoryId}`);
        // } catch (error) {
        //     console.error(error);
        //     // Handle errors, e.g., display an error message
        // }
    };


    return (
        <>
            <section className='vcvc rr' style={{ background: "url(27.png)", backgroundAttachment: "fixed" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-1 col-2'>
                            <img src={img11} style={{ width: "100%" }} alt="logo" />
                        </div>
                        
                        <div className=' uc col-md-11 col-10 mb-2'>
                             <h3 className='uu'><span>Category</span>List</h3>
                        </div>
                       

                            <div className='col-md-12'>
                                <div id="carouselExampleControlsfyfklulhefwf" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {categories.reduce((chunks, category, index) => {
                                            const chunkIndex = Math.floor(index / 5);
                                            if (!chunks[chunkIndex]) {
                                                chunks[chunkIndex] = [];
                                            }
                                            chunks[chunkIndex].push(category);
                                            return chunks;
                                        }, []).map((chunk, chunkIndex) => (
                                            <div className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`} key={chunkIndex}>
                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                                                    {chunk.map(category => (
                                                        <li key={category._id} className="nav-item" role="presentation">
                                                            <button
                                                                className={`nav-link ${selectedCategory === category._id ? 'active' : ''}`}
                                                                onClick={() => handleCategorySelect(category._id)}
                                                                id={`pills-${category.name.replace(/\s/g, '').toLowerCase()}-tab`}
                                                                data-toggle="pill"
                                                                data-target={`#pills-${category.name.replace(/\s/g, '').toLowerCase()}`}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={`pills-${category.name.replace(/\s/g, '').toLowerCase()}`}
                                                                aria-selected={selectedCategory === category._id ? 'true' : 'false'}
                                                            >
                                                                {category.name} 
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulhefwf" data-slide="prev">
                                        <img src={imgcopy22} style={{ width: "25%" }} alt='sarkariexam' />
                                    </button>
                                    <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulhefwf" data-slide="next">
                                        <img src={img22} style={{ width: "25%" }} alt='sarkariexam' />
                                    </button>
                                </div>
                            </div>

                    </div>
                </div>
            </section>

            <section className='vcvc rr' style={{ background: "url(27.png)", backgroundAttachment: "fixed" }}>
                <div className='container'>
                {subCategories.length > 0 && (
                
                    <div className='row'>
                        <div className='col-md-1 col-2'>
                            <img src={img11} style={{ width: "100%" }} alt="logo" />
                        </div>
                        
                        <div className=' uc col-md-11 col-10 mb-2'>
                             <h3 className='uu'><span>Sub Category</span>List</h3>
                        </div>
                       

                            <div className='col-md-12'>
                                <div id="carouselExampleControlsfyfklulhefwfe" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {subCategories.reduce((chunks, subcategory, index) => {
                                            const chunkIndex = Math.floor(index / 10);
                                            if (!chunks[chunkIndex]) {
                                                chunks[chunkIndex] = [];
                                            }
                                            chunks[chunkIndex].push(subcategory);
                                            return chunks;
                                        }, []).map((chunk, chunkIndex) => (
                                            <div className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`} key={chunkIndex}>
                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                                                    {chunk.map(subcategory => (
                                                        <li key={subcategory._id} className="nav-item" role="presentation">
                                                            <button
                                                                className={`nav-link ${selectedCategory === subcategory._id ? 'active' : ''}`}
                                                                onClick={() => handleSubCategorySelect(subcategory._id)}
                                                                id={`pills-${subcategory.name.replace(/\s/g, '').toLowerCase()}-tab`}
                                                                data-toggle="pill"
                                                                data-target={`#pills-${subcategory.name.replace(/\s/g, '').toLowerCase()}`}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={`pills-${subcategory.name.replace(/\s/g, '').toLowerCase()}`}
                                                                aria-selected={selectedCategory === subcategory._id ? 'true' : 'false'}
                                                            >
                                                                {subcategory.name} 
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulhefwfe" data-slide="prev">
                                        <img src={imgcopy22} style={{ width: "25%" }} alt='sarkariexam' />
                                    </button>
                                    <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulhefwfe" data-slide="next">
                                        <img src={img22} style={{ width: "25%" }} alt='sarkariexam' />
                                    </button>
                                </div>
                            </div>

                    </div>
                    )}
                </div>
            </section>

            {/* <section className='lloo'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className="tab-content" id="pills-tabContent">
                                {subCategories.length > 0 && (
                                    <section>
                                        <div className='container'>
                                            <div className='row'>
                                                {subCategories.map((subcategory, index) => (
                                                    <div key={subcategory._id} className='col-md-6 pt-2 pb-2'>
                                                       
                                                        <div className="card" style={{ borderRadius: "20px", background: "white", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                                            <div className="card-body" style={{ padding: "10px", background: "white", borderRadius: "20px" }}>
                                                                <div className="row">
                                                                    <div className='col-md-4 col-3'>
                                                                        <img src={`${process.env.REACT_APP_BASE_URL}/${subcategory.image}`} style={{ width: "60%" }} alt="subcategory" />
                                                                    </div>
                                                                    <div className='col-md-8 col-9'>
                                                                        <button
                                                                            className={`btn btn-link btn-lg ${selectedSubCategory === subcategory._id ? 'active' : ''}`}
                                                                            onClick={() => handleSubCategorySelect(subcategory._id)}
                                                                            style={{ color: "light blue", textDecoration: "none", cursor: "pointer", display: "block", textAlign: "left" }}
                                                                        >
                                                                            {subcategory.name}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </section>
                                )}
                               
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <p className='btn btn-lg btn-block mt-3 mb-2' style={{ borderRadius: "68px", background: "linear-gradient(45deg, #6a6de2, #8ad1c6)", color: "rgb(255 255 255)", fontSize: "25px", fontWeight: "600" }}>Explore All Exams</p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
}

export default Category;
