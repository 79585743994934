
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import pic1 from "../../images/01.png"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function ExamStructure() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [examStructure, setExamStructure] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState([]);
    const [testSeries, setTestSeries] = useState(null);
    const [structureId, setStructureId] = useState(null);
    const { test_series_id } = useParams();
    useEffect(() => {
      const fetchTestSeriesDetails = async () => {
      try {
          // Retrieve token from localStorage
          // const authToken = localStorage.getItem('token');
        
          // // Check if user is authenticated
          // if (authToken==null) {
              
          //     navigate(process.env.PUBLIC_URL+'/login');
          //     // Redirect user to login page
              
          // }

          // Fetch test series details using the test_series_id
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/test_series?test_series_id=${test_series_id}`, {
              headers: {
                  'Authorization': ''
              }
          });
          console.log(response.data.test_series);
          setTestSeries(response.data.test_series);
          setIsLoading(false);
      } catch (error) {
          setError(error.message);
          setIsLoading(false);
      }
  };
         fetchExamStructure();
         fetchTestSeriesDetails();
    },[]);

    

    const fetchExamStructure = async () => {
        try {
            const authToken = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/exam_structure?test_series_id=${test_series_id}`, {
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                  }
              });
         
              setExamStructure(response.data);
              
              console.log("PANKAJ",response.data);
              
                  setIsLoading(false);
              // Set selectedItemDetails with details of the first item if available
              if (response.data && response.data.structure && response.data.structure.length > 0) {
                  const firstItemId = response.data.structure[0]._id;
                  console.log(response.data);
                  handleItemClick(firstItemId);
                  
              }
            
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
            setExamStructure([]);
        }
    };
    function formatDate(date) {
      
       const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    }

    const handleItemClick = async (itemId) => {
        try {
            const authToken = localStorage.getItem('token');
            if(authToken!=null){
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                  }
              });
              setSelectedItemDetails(response.data);
              console.log(selectedItemDetails);

            }else{
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series_without_login?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                    'Authorization': ''
                }
              });
              console.log("RAHUL",response.data);
              setSelectedItemDetails(response.data);
                            
             

            }
          
           
        } catch (error) {
          setSelectedItemDetails([]);
            console.error('Error fetching test series:', error.message);
        }
    };
  return (
    <>
      <section className='t'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
            {testSeries && (
              <div className='row'>
                <div className='col-md-1 col-2'>
                  <img src={`${process.env.REACT_APP_BASE_URL}/${testSeries.image}`} style={{ width: "100%" }} />
                </div>
                <div className='col-md-11 col-10'>
                  <h3 className="card-title">{testSeries.title}</h3>
                </div>
                <div className='col-md-12'>
                  <p>Last updated on Mar 23, 2024</p>
                </div>

                <div className='col-md-3'>
                  <p className='aaa' style={{ fontWeight: "600" }}>244 Total Tests </p>
                </div>
                <div className='col-md-3'>
                  <div className='aaaa'>
                    <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <p className='aaa' style={{ fontWeight: "600" }}>187.7k Users</p>
                </div>
                <div className='col-md-3'>
                  <p style={{ fontWeight: "600" }}>English,Hindi</p>
                </div>
                <div className='col-md-12'>
                  <hr style={{ border: "1px solid #000", margin: "0px" }} />
                </div>
                {examStructure && examStructure.structure?.map(item => (
                    <div className='col-md-4' key={item._id}>
                        <ul>
                            <li >
                                <strong>{item.name}:</strong> ({item.total_test})<br />
                            </li>
                        </ul>
                    </div>
                ))}
              </div>
             )}
              {/* <div className='btn btn-danger btn-block mb-2'>Add This Test Series  </div> */}
            </div>
            <div className='col-md-4'>
              <div className='fform ' >
                <h5 className='text-center'>Sign up To Test Your Exam Knowledge Now!</h5>
                <form>
                <center><img src={pic1} className="Apogo" alt="logo" /></center>
                  {/* <input className='form-control mt-2 mb-2 text-center' type='text' placeholder='Enter Your Mobile Number' /> */}
                  {
                                        !token && (
                                          <a href="/login" style={{ textDecoration: "none" }}><p className='btn btn-block btn-success'>Signup & Take Free Tests</p></a> 
                                            // <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/login">Login</a>
                                        ) 
                                    }
                                    {token && ( <a className='btn btn-block btn-success' style={{ paddingLeft: "10px" }} href="/profile">Profile</a> )}
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xs">
        <div className="container">
        {/* {testSeries && ( */}
        {/* {testSeries.title} */}
          {/* <h2>Title</h2> */}
          <div className="row">
            <div className="col-md-12">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", overflowX: "auto", overflowY: "hidden", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
              {examStructure && examStructure.structure?.map((item, index) => (
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button 
                    className={`nav-link ${index === 0 ? 'active' : ''}`} 
                  id={`pills-${item._id}-tab`} 
                    data-toggle="pill" 
                    data-target={`#pills-${item._id}`} 
                    type="button" 
                    role="tab" 
                    onClick={() => handleItemClick(item._id)}
                    aria-controls={`pills-${item._id}`} 
                    aria-selected={index === 0 ? 'true' : 'false'}
                  >
                    <strong>{item.name}:</strong> ({item.total_test})<br />
                  </button>
                </li>
              ))}
            </ul>
              <div className="tab-content" id="pills-tabContent">
             {selectedItemDetails.test?.map((items, index) => (
                      <div className= {`tab-pane fade show active`}  id={`#pills-${items.exam_structure}`}  role="tabpanel" >
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 wow animated fadeInLeft" data-wow-duration="2s" data-wow-delay="1s">
                              <div className="coo zoom">
                                <a href="#" style={{ textDecoration: "none" }}>
                                  <div className="row">
                                    <div className="col-md-9 col-10">
                                      <p className="text-left text-bold">
                                        {items.title}  {items.isLocked ? (
                                          <i class="fa fa-lock" aria-hidden="true"></i>
                                        ) : (
                                          <p></p> // Or any other element you want to render when items are not locked
                                        )}
                                      </p>
                                      <div className='row'>
                                        <div className='col-md-12'>
                                          <p>Available from  {formatDate(items.updatedAt)} at {items.start_time}</p>
                                        </div>
                                        <div className='col-md-4'>
                                          <p className='aaa' style={{ fontWeight: "600" }}>{items.total_question} Questions </p>
                                        </div>
                                        {/* <div className='col-md-4'>
                                          <div className='aaaa'>
                                            <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                          </div>
                                        </div> */}
                                        <div className='col-md-4'>
                                          <p className='aaa' style={{ fontWeight: "600" }}>{(items.per_question_mark) * (items.total_question)} Marks</p>
                                        </div>
                                        <div className='col-md-4'>
                                          <p style={{ fontWeight: "600" }}> {items.total_duration} Mins</p>
                                        </div>
                                      </div>
                                    </div>
                                      <div className="col-md-3 col-12">

                                        {items.isLocked ? (
                                          <a href={`/package_details/${items.sub_category_id.category_id._id}/${items.sub_category_id._id}/${test_series_id}`} className='btn btn-danger btn-block'>Start Now</a>
                                          
                                        ) : (
                                          <a href={`/teststart/${items._id}`} className='btn btn-green btn-block'>Start Now</a>
                                           // Or any other element you want to render when items are not locked
                                        )}
                                      </div>
                                      {/*  */}
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        {/* // )} */}
        </div>
      </section>














    </>
  )
}