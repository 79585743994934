import Header from '../components/header';
import Footer from '../components/footer';

import React, { useState, useEffect, useRef } from 'react'

export default function DeletionData(){
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [phone,setPhone]=useState("");
    const handleSubmit = (event) => {
        const form = event.currentTarget
       const access_token="";
        // setValidated(true)
        const api_url=process.env.REACT_APP_BASE_URL
        const formData = new FormData()
        // formData.append('image', image)
        
       
        formData.append('email',email);  
        
        formData.append('name',name); //tags
        formData.append('phone',phone); //tags
        
        
        
        //   access_token=JSON.parse(sessionStorage.getItem('user')).access_token
            // access_token="Bearer "+access_token;
           
            const result = fetch(api_url + '/user/request_data_deletion', {
                method: 'post',
                headers: {
                    "Authorization": access_token,
                    // Other headers if needed
                },
                body: formData,
            }).then((response) => {
                console.log(response);
                if(response.status.ok){
                alert(' Added successfully!!')
                // navigate('/admin/AddTest-list')
                }else{
                    alert(' Added successfully!!')
                // navigate('/admin/AddTest')
                }
            })
        
    }
  return(
    <>
      <Header />
      {/* <img className='srimg' src="ccon.jpg" width="100%" style={{ borderRadius: 20 }} /> */}
      <div className="contact">
        <div className="container">
          <div className="section-header text-center">
            <h2>Request Data Deletion</h2>
          </div>
         
          <div className="row contact-form">
           
            <div className="col-md-12">
              <div id="success" />
              <form name="sentMessage" id="contactForm" noValidate="novalidate">
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    required="required"
                    data-validation-required-message="Please enter your name"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    placeholder="Your Email"
                    required="required"
                    data-validation-required-message="Please enter your email"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="Phone"
                    placeholder="Phone"
                    onChange={(e) => {
                        setPhone(e.target.value)
                    }}
                    required="required"
                    data-validation-required-message="Please enter a subject"
                  />
                  <p className="help-block text-danger" />
                </div>
                
                <div>
                  <button
                    className="btn custom-btn"
                    style={{ background: "#69c9ff", border: "2px solid #69c9ff" }}
                    type="button"
                    id="sendMessageButton"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}