import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Leaderboard(){
    const { test_id } = useParams();
    const [resultList, setResultList] = useState(null);
    const [rankList, setRankList] = useState([]);
   
    const [access_token, setAccessToken] = useState(""); 
    const Navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
       
        if (token) {
            setAccessToken(token);
           
                try {
                    fetch(`${process.env.REACT_APP_BASE_URL}/user/get_first_attempt_test_marks?test_id=${test_id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": "Bearer "+token,
                            // Other headers if needed
                        },
                    })
                    .then(response => response.json())
                    .then(result => setResultList(result))
                    .catch(error => console.log(error));
                } catch (error) {
                    console.error("Error fetching test question details:", error);
                }
        } else {
            Navigate('/login');
        }
        

        if (token) {
          setAccessToken(token);
          try {
              fetch(`${process.env.REACT_APP_BASE_URL}/user/get_leader_board?test_id=${test_id}`, {
                  headers: {
                      'Content-Type': 'application/json',
                      "Authorization": "Bearer "+token,
                      // Other headers if needed
                  },
              })
              .then(response => response.json())
              .then(result => setRankList(result))
              .catch(error => console.log(error));
          } catch (error) {
              console.error("Error fetching test Leaderboard details:", error);
          }
       



        




      } else {
          Navigate('/login');
      }


     

    }, [test_id]);

    const handleleaderboard = async () =>{
      const token = localStorage.getItem('token'); 
      if (token) {
        Navigate('/leaderboard/' + test_id);
     }else{
      Navigate('/login');
     }
    }

    
  return(
    <>
      {/* <Header /> */}
      <img className='srimg' src="abt.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section className="cc">
    <div className="container">
      
      <div className="row">
        <div className="col-md-8">
        <h3 style={{ color: "#2e1b1d" }}>{ resultList?.test_marks.test_id.title }</h3>
      <hr style={{ border: "1px solid #f27e18" }} />

         
        </div>
        <div className="col-md-4">
            
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${resultList?.test_marks.test_id.image}`}
            width="50%"
            style={{ borderRadius: 20 }}
          />
        </div>
      </div>
    </div>
  </section>
  <section className="cons" id="counter"style={{
      background: "url(dfddfvbds.png)",
      backgroundAttachment: "fixed",
      backgroundSize: "cover"
    }}>
    <div className="container">
      <h3 style={{ color: "#2e1b1d" }}>LEADERBOARD</h3>
      <hr style={{ border: "1px solid #f27e18" }} />
      <div className="row">
        <div className="col-md-12 col-12">
            <table className='table'>
                <thead>
                    <tr>
                        <th colSpan={3}>LEADERBOARD</th>
                       
                    </tr>
                </thead>
                <tbody>
                {rankList && rankList.leader_board?.map((item, index) => (
                <tr>
                    <td>{index+1}</td>
                    <td><img
            src={`${process.env.REACT_APP_BASE_URL}/${item.user_id.profile_image}`}
            width="30%"
            height="100px"
            style={{ borderRadius: 50 }}
          /></td>
                    <td>{item.total_marks}/{item.test_id.total_question * item.test_id.per_question_mark}</td>
                </tr>

                ))}
                    
                </tbody>
            </table>
        </div>
       
      </div>
    </div>
  </section>
 





    {/* '''''''''''''''''''''''''''''''''''''''second attempt''''''''''''''''''''''''''''''''''''''''''''''''''''''''' */}
    
   

  {/* '''''''''''''''''''''''''''''''''''''''''Third Attempt''''''''''''''''''''''''''''''''''''''''''''''''''' */}




      {/* <Footer /> */}
    </>
  )
}