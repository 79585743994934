import Header from '../header';
import Footer from '../footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink ,useParams} from 'react-router-dom';
export default function Testseries() {
    
    const [testSeries, setTestSeries] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const { text } = useParams('text');
  
    
   
    useEffect(() => {
        const fetchTestSeries = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/search_test_series?keyword=${text}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                console.log(response.data);
                
                setTestSeries(response.data);
                setIsLoading(false);
            } catch (error) {
               
                setIsLoading(false);
            }
        };

        fetchTestSeries();
    }, [text]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }
  return (
    <>
      <Header />
      <img className='srimg' src="ts.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section
  className="dd"
  style={{
    background: "url(dfddfvbds.png)",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <h3 style={{ color: "#2e0d78" }}>Test Series</h3>
    <hr style={{ border: "1px solid red" }} />
    <div className="row">
        {testSeries && testSeries.test_series?.map((item, index) => (


            <div className="col-md-4 col-12">
            <div className="item">
            <div className="card" style={{
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    borderBottom: '10px solid #007bff !important'
  }}>
            <img src={`${process.env.REACT_APP_BASE_URL}/${item.image}`} className="card-img-top" alt="..." />
                
                <div className="card-body">
                <h6 className="card-title text-center">
                    {item.title}
                </h6>
                {/* <p class="card-text">Test Series is equipped with the team of top most faculties for preparation Class - 6 Exams</p> */}
                <center>
                    <a href={`/testseries_details/${item._id}`} className="btn btn-primary">
                    Visit Series
                    </a>
                </center>
                </div>
            </div>
            </div>
            </div>







        ))}
      
    </div>
  </div>
</section>

      <Footer />
    </>
  )
}