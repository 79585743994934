
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useParams,  Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function ExamStructure() {
    const navigate = useNavigate();
    const [examStructure, setExamStructure] = useState([]);
    const [packageCategory, setPackageCategory] = useState([]);
    const [coupandetails, setCoupandetails] = useState([]);
    const [packageAll, setPackageAll] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState([]);
   
    const { test_series_id } = useParams(0);
    const { price_id } = useParams(1);
    useEffect(() => {
        const authToken = localStorage.getItem('token');
        if (authToken) {
            const fetchCoupan = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/price`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        },
                        params: {
                          price_id: price_id,
                        }
                        
                    });
                    console.log("Mohan", response);
                    setPackageCategory(response.data)
                } catch (error) {
                    console.error('Error fetching category package', error);
                }
            };
            const fetchdetails = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/coupon`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        },
                        params: {
                          test_series_id: test_series_id,
                        }
                    });
                    // console.log("Mohan2", response);
                    setCoupandetails(response.data)
                    console.log("Mohan2", coupandetails);
                } catch (error) {
                    console.error('Error fetching category package', error);
                }
            };

           
            fetchCoupan();
            fetchdetails();
        } else {
            navigate('/login');
        }
    }, [price_id,test_series_id, navigate]);

    

   
   

    const handleItemClick = async (itemId) => {
        try {
            const authToken = localStorage.getItem('token');
            if(authToken!=null){
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                  }
              });
              setSelectedItemDetails(response.data);
              console.log(selectedItemDetails);

            }else{
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series_without_login?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                    'Authorization': ''
                }
              });
              console.log("RAHUL",response.data);
              setSelectedItemDetails(response.data);
                            
             

            }
          
           
        } catch (error) {
          setSelectedItemDetails([]);
            console.error('Error fetching test series:', error.message);
        }
    };
  return (
    <>
      {/* <section className='t'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
           
            </div>
        </div>
        </div> 
      </section> */}

      <section className="xs">
        <div className="container">
          <div className="row t">
            <div className="col-md-12">
            <h6 className="text-primary text-uppercase">{packageCategory.price?.title}</h6>
             
            </div>

            <div className="col-md-6">
            <h6 className="text-primary text-uppercase">{packageCategory.price?.price}</h6>
             
            </div>
            <div className="col-md-6">
            <h6 className="text-primary text-uppercase">{packageCategory.price?.duration} Months</h6>
             
            </div>
           
           <div className="col-md-12"><hr/></div>
          
           
           {coupandetails && coupandetails.coupon?.map((item, index) => (
                 <div className="col-md-12 bg-info mb-2 p-3">
                    <div className="row text-light">
                        <div className='col-md-3'><h5>{item.title}</h5></div>
                        <div className='col-md-3'><span>{item.desc}/-</span></div>
                        </div>
                 </div>
                 
            ))}
             <div className="col-md-12"><hr/></div>
             <div className="col-md-12"><a href="" className='btn btn-sm btn-danger form-control'>Order </a></div>
          </div>
        </div>
      </section>














    </>
  )
}