
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink ,useParams} from 'react-router-dom';    
import Header from '../components/header';
import Footer from '../components/footer';

export default function Jankari() {
    const [TestSeries, setTestSeries] = useState([]);
    const [TestSeriesPdf, setTestSeriesPdf] = useState([]);

    // Fetch categories when the component mounts
    useEffect(() => {
       

        fetchTestSeries();
        fetchTestpdf();
    }, []);
    const fetchTestSeries = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/test_series?type=daily`);
            const data = await response.json();
           
          
            
            setTestSeries(data.test_series);
            
        } catch (error) {
            console.error(error.message);
            
        }
    };

    const fetchTestpdf = async () => {
        try {
            const authToken = localStorage.getItem('token');
           
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/per_day_jankari`);
            const data = await response.json();
          
          
            
            setTestSeriesPdf(data.per_day_jankari);
            console.log("Ram",TestSeriesPdf)
            
        } catch (error) {
            console.error(error.message);
            
        }
    };


  return (
    <>
      <Header />
      <img className='srimg' src="k=jankari.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section
  className="dd"
  style={{
    background: "url(dfddfvbds.png)",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <h3 style={{ color: "#2e0d78" }}>Daily Dose of test</h3>
    <hr style={{ border: "1px solid red" }} />
    <div className="row">

    <div className='col-md-6'>
    <div className="row">
    <div className='col-md-12'> <h3 style={{ color: "#2e0d78" }}>Per Day Jankari</h3> <hr style={{ border: "1px solid green" }} /></div>   
    
    {/* pdfimage.png */}
     { TestSeriesPdf?.length>0 ?(
            TestSeriesPdf?.map((item,index) =>(
                <div className="col-md-6 col-12">
                <div className="item">
                  <div className="card">
                    <img  src="pdfimage.png" height={"200px"} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h6 className="card-title text-center">
                      {item.title}
                      </h6>
                      {/* <p class="card-text">Test Series is equipped with the team of top most faculties for preparation Class - 6 Exams</p> */}
                      <center>
                        <a href={`${process.env.REACT_APP_BASE_URL}/${item.file}`} className="btn btn-primary">
                          Visit Series
                        </a>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : (
            'Loading'
          )}

</div>
    </div>
    <div className='col-md-6'>
        
    <div className="row">
    <div className='col-md-12'> <h3 style={{ color: "#2e0d78" }}>Daily Test Series</h3> <hr style={{ border: "1px solid green" }} /></div>   
    { TestSeries?.length>0 ?(
            TestSeries?.map((item,index) =>(
                <div className="col-md-6 col-12">
                <div className="item">
                  <div className="card">
                    <img  src={`${process.env.REACT_APP_BASE_URL}/${item.image}`} height={"200px"} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h6 className="card-title text-center">
                      {item.name}
                      </h6>
                      {/* <p class="card-text">Test Series is equipped with the team of top most faculties for preparation Class - 6 Exams</p> */}
                      <center>
                        <a href={`/testseries_details/${item._id}`} className="btn btn-primary">
                          Visit Series
                        </a>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

            ))
        ) : (
            'Loading'
          )}

    </div>
    </div>

       

     



      
    </div>
  </div>
</section>

      <Footer />
    </>
  )
}