import Header from '../components/header';
import Footer from '../components/footer';

export default function Term() {
    return (
        <>
            <Header />
            <img className='srimg' src="term_condition.jpg" width="100%" style={{ borderRadius: 20 }} />
            <section style={{ background: "url(dfddfvbds.png)", backgroundAttachment: "fixed", backgroundSize: "cover" }}>

                <div className='container'>

                    <h3 style={{ color: "#2e1b1d",paddingTop:"20px" }}>Terms and Conditions for Pensbook</h3>
                    <hr style={{ border: "1px solid #f27e18" }} />
                    <div className='row'>
                        <div className='col-md-12'>
                        <h4>1. Introduction</h4>
                        <p style={{ textAlign: 'justify' }}>Welcome to Pensbook. These terms and conditions govern your use of our platform and services, including the purchase and use of test series packages for government exams. By accessing or using Pensbook, you agree to comply with and be bound by these terms and conditions.</p>
                        <h4>2. Eligibility</h4>
                        <p style={{ textAlign: 'justify' }}>By using Pensbook, you affirm that you are at least 18 years old or have reached the age of majority in your jurisdiction and have the legal capacity to enter into these terms and conditions.</p>

                        <h4>3. Account Registration</h4>
                        <p style={{ textAlign: 'justify' }}>To access certain features of Pensbook, including purchasing test series packages, you must register for an account. You agree to provide accurate and complete information during the registration process and to keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                        <h4>4. Test Series Packages</h4>
                        <h5>4.1. Purchase and Access</h5>
                        <p style={{ textAlign: 'justify' }}>Pensbook offers various test series packages for government exams. By purchasing a package, you gain access to the test series included in that package for the duration specified at the time of purchase.</p>
                        <h5>4.2. Pricing and Payment</h5>
                        <p style={{ textAlign: 'justify' }}>The pricing for test series packages will be as indicated on the platform at the time of purchase. All payments must be made through the payment methods provided on the platform. Prices are subject to change, but changes will not affect orders that have already been placed.</p>
  
                        <h5>4.3. Refunds</h5>
                        <p style={{ textAlign: 'justify' }}>All purchases are final and non-refundable. We do not provide refunds or credits for any partially used or unused services.</p>
                        <h4>5. Use of Platform</h4>
                        <p  style={{ textAlign: 'justify' }}>You agree to use Pensbook only for lawful purposes and in accordance with these terms and conditions. You agree not to use the platform:</p>
                        <ul>
                            <li>In any way that violates any applicable federal, state, local,</li>
                            <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
                            <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                            <li>To engage in any conduct that restricts or inhibits anyone's use or enjoyment of Pensbook, or which, as determined by us, may harm Pensbook or users of Pensbook, or expose them to liability.</li>
                        </ul>

                        <h4>6. Intellectual Property</h4>
                            <p style={{ textAlign: 'justify' }}>The content on Pensbook, including but not limited to text, graphics, logos, images, and software, is owned by or licensed to Pensbook and is subject to copyright and other intellectual property rights under applicable laws.</p>

                            <h4>7. Privacy Policy</h4>
                            <p style={{ textAlign: 'justify' }}>Your privacy is important to us. Please review our <a href="https://pensbook.com/Privacy">Privacy Policy</a> to understand how we collect, use, and disclose information about you.</p>

                            <h4>8. Limitation of Liability</h4>
                            <p style={{ textAlign: 'justify' }}>In no event shall Pensbook, its affiliates, or their respective officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of Pensbook or the test series packages, regardless of whether Pensbook has been advised of the possibility of such damages.</p>

                            <h4>9. Governing Law</h4>
                            <p style={{ textAlign: 'justify' }}>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

                            <h4>10. Changes to Terms and Conditions</h4>
                            <p style={{ textAlign: 'justify' }}>We reserve the right to modify or update these terms and conditions at any time. Any changes will be effective immediately upon posting the revised terms and conditions on Pensbook. Your continued use of Pensbook after the posting of the revised terms and conditions constitutes your acceptance of such changes.</p>

                            <h4>11. Contact Us</h4>
                            <p style={{ textAlign: 'justify' }}>If you have any questions about these terms and conditions, please contact us at <a href="mailto:info@pensbook.com">info@pensbook.com</a>.</p>

                            <p style={{ textAlign: 'justify' }}>Last Updated: [27-06-2024]</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}