import Header from '../header';
import Footer from '../footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import img08 from "../../images/08.png"
import img22 from "../../images/22Copy.png"
import { useParams,  Link, useNavigate } from 'react-router-dom';

// import TestStructureDetails from './examStructureDetails';
import TestexamStructure from './examStructure';

const SeriesDetails = () => {

    const [testSeries, setTestSeries] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get the test_series_id from the URL
    const { test_series_id } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        const fetchTestSeriesDetails = async () => {
             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/test_series?test_series_id=${test_series_id}`, {
                    
                });
                console.log(response)
                setTestSeries(response.data.test_series);
                setIsLoading(false);
            // try {
           
        };

        fetchTestSeriesDetails();
    }, [test_series_id]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <>
            <Header />
            
            <section class="newss">
                {testSeries && (
                    <>
                        <div className="row files">
                            <div className="col-md-4"></div>
                            <div className="col-md-1"><img src={`${process.env.REACT_APP_BASE_URL}/${testSeries.image}`} alt="Test Series" style={{ width: "70%", float: "right" }} /></div>
                            <div className="col-md-6">
                                <h3>{testSeries.title}</h3>
                                <div className="mt-3">
                                    <span className="bg-primary rounded p-1 text-light">Test Details</span>
                                    <div id="google_translate_element"></div>
                                </div>
                            </div>
                              <div className="col-md-1"></div>
                        </div>

                        {/* Other details */}
                    </>
                )}
                {/* <TestStructureDetails /> */}
                <TestexamStructure/>
            </section>

            <section className='vcvc'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-1 col-2'>
              <img src={img08} style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
              <h3 className='uu'><span>Video Classes</span> Discussion</h3>
            </div>
            <div className='col-md-12'>
              <div id="carouselExampleControlsfyfklulh" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button class="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>
                  <div className="carousel-item">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li className="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>

                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="prev">
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Previous</span> */}
                  <img src={img22} style={{ width: "25%" }} alt='sarkariexam' />
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="next">
                  {/* <span className="carousel-control-next-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Next</span> */}
                  <img src='22.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='lloo'>
          <div className='container'>
            <div className='row justify-content-center'>

              <div className='col-md-12'>

                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-ssc" role="tabpanel" aria-labelledby="pills-ssc-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gXhdno374Og?si=BzoVmxPYExzFGxR7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/-1einz1-8OU?si=h0Dt10N3JRgscYMa" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/fdDa2jeGx5g?si=lQIWEcRhIKKelEIY" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-railway" role="tabpanel" aria-labelledby="pills-railway-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/8fXtK8O8YeA?si=_bHmmzikY3C_GwB2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/5Gy7LAG7uV8?si=FYC_lP1NEn8r1yIM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/nBjdUJlRZUc?si=B3qqjiiZi1c28UzM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-defence" role="tabpanel" aria-labelledby="pills-defence-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/7y_tlPQFtk0?si=-gOwo4tWlXTVgX4Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CZyRrNgb6qU?si=yPDmN229QhOJDcW7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CvjcN6ErK34?si=bIAIrA00iZnoYhL2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-banking" role="tabpanel" aria-labelledby="pills-banking-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/BGN5IUSLk_c?si=4ObMw-AgAFv15hcR" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gpA7BpubhdU?si=CTFTlQGbGnPvc8VW" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/EpTupTIKRCM?si=fbDh6oDaf3isBA1w" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-states" role="tabpanel" aria-labelledby="pills-states-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/xQyE0Qzz6XE?si=DebtTLR-6PBN37FG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/zNPKQjAFsvc?si=9S4pTuEs85dzrLmz" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/KO3gtJh9XV8?si=2HmWP-2bINCFz8zm" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                </div>
                <div className='col-md-12'>
                  <p className='btn btn-lg btn-block mt-3 mb-2' style={{ borderRadius: "20px", background: "#fff", color: "#898989", fontSize: "25px", fontWeight: "600" }}>A Complete MockTest Discussion Video Support For Your Better Preparation</p>
                </div>
              </div>



            </div>
          </div>
        </section>
      </section>
            <Footer />
        </>
    );
};

export default SeriesDetails;
