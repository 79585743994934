import React, { useState, useEffect } from 'react';
import { useLocation, useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';

const DetailsOfTest = () => {
    const { testId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    
    const { title, totalDuration, totalMarks, language } = location.state;
    const [optionalPapers, setOptionalPapers] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedPaper, setSelectedPaper] = useState(''); // Add state for selected optional paper

    useEffect(() => {
        const fetchOptionalPapers = async () => {
            try {
                const authToken = localStorage.getItem('token');
                if (!authToken) {
                    console.error('Authentication token not found');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_optional_section?test_id=${testId}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });

                console.log(response,"response")
                setOptionalPapers(response.data.test_section_details);
            } catch (error) {
                console.error('Error fetching optional papers:', error);
            }
        };
        fetchOptionalPapers();
    }, [testId]);

    // Handle language change event
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    // Handle optional paper change event
    // const handlePaperChange = (event) => {
    //     setSelectedPaper(event.target.value);
    // };

// Handle optional paper change event
const handlePaperChange = (event) => {
    const selectedPaperId = event.target.value;
    setSelectedPaper(selectedPaperId);
    console.log("Selected Paper ID:", selectedPaperId); // Add this line to log selected paper ID
};

    const handleClickNavigate = () => {
        if (selectedPaper) {
            navigate(`/section_details/${testId}`, {
                state: {
                    selectedPaper: selectedPaper, // Pass the selected paper ID instead of its name
                    selectedLanguage: selectedLanguage,
                    testId: testId,
                    totalDuration :totalDuration
                }
            });
        }
    };
    

    
    return (
        <div>
            <h1 style={{ fontWeight: 'bold', marginBottom: '20px' }}>Title: {title}</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                <p><strong>Total Duration:</strong> {totalDuration}</p>
                <p><strong>Total Marks:</strong> {totalMarks}</p>
            </div>
            <div style={{ marginTop: '300px' }}>
                <p style={{ marginBottom: '20px' }}>Select Language (you can change it later also)</p>
                <select value={selectedLanguage} onChange={handleLanguageChange}>
                <option value="">Select Language Paper</option>
                    {language.map(lang => (
                        <option key={lang._id} value={lang.name}>{lang.name}</option>
                    ))}
                </select>
            </div>

            <div style={{ marginTop: '30px' }}>
                <p style={{ marginBottom: '10px' }}>Select Optional Paper (you cannot change it later)</p>
                <select value={selectedPaper} onChange={handlePaperChange}>
                    <option value="">Select Optional Paper</option>
                    {optionalPapers.map(paper => (
                        <option key={paper._id} value={paper.test_section_id._id}>{paper.test_section_id.name}</option>
                    ))}
                </select>
            </div>
            <div className="mt-3">
                <button className="btn btn-lg bg-purple-300 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded" onClick={handleClickNavigate}>
                    Continue
                </button>
            </div>
        </div>
    );
};
export default DetailsOfTest;

