import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

export default function FinalResult(){
    const { test_id } = useParams();
    const [resultList, setResultList] = useState(null);
    const [resultSecList, setResultSecList] = useState(null);
    const [resultThirdList, setResultThirdList] = useState(null);
    const [rank, setRank] = useState(null);
    const [access_token, setAccessToken] = useState(""); 
     const [checkAttempt, setCheckAttempt] = useState();
     const [checkSecAttempt, setCheckSecAttempt] = useState();
     const [checkThirdAttempt, setCheckThirdAttempt] = useState();
    const Navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        // alert(token)
        if (token) {
            setAccessToken(token);
            try {
                fetch(`${process.env.REACT_APP_BASE_URL}/user/get_first_attempt_test_marks?test_id=${test_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer "+token,
                        // Other headers if needed
                    },
                })
                .then(response => response.json())
                .then(result => { setResultList(result); console.log("MOHAN FIRST",resultList) })
                .catch(error => console.log(error));
            } catch (error) {
                console.error("Error fetching test question details:", error);
            }
              
            try {
              fetch(`${process.env.REACT_APP_BASE_URL}/user/get_second_attempt_test_marks?test_id=${test_id}`, {
                  headers: {
                      'Content-Type': 'application/json',
                      "Authorization": "Bearer "+token,
                      // Other headers if needed
                  },
              })
              .then(response => response.json())
              .then(result => {setResultSecList(result);
                  console.log("QWERDR",resultSecList)})
              .catch(error => console.log(error));
          } catch (error) {
              console.error("Error fetching test question details:", error);
          }

            // Third result
            try {
              fetch(`${process.env.REACT_APP_BASE_URL}/user/get_third_attempt_test_marks?test_id=${test_id}`, {
                  headers: {
                      'Content-Type': 'application/json',
                      "Authorization": "Bearer "+token,
                      // Other headers if needed
                  },
              })
              .then(response => response.json())
              .then(result => setResultThirdList(result))
              .catch(error => console.log(error));
          } catch (error) {
              console.error("Error fetching test question details:", error);
          }
        } else {
            Navigate('/login');
        }

        console.log("resultList",resultList);
        if (token) {
          setAccessToken(token);
          try {
              fetch(`${process.env.REACT_APP_BASE_URL}/user/get_leader_board?test_id=${test_id}&type=single`, {
                  headers: {
                      'Content-Type': 'application/json',
                      "Authorization": "Bearer "+token,
                      // Other headers if needed
                  },
              })
              .then(response => response.json())
              .then(result => setRank(result))
              .catch(error => console.log(error));
          } catch (error) {
              console.error("Error fetching test question details:", error);
          }

           //   '''''''''''''''''''check attempt First''''''''''''''''''''''''
        try {
          fetch(`${process.env.REACT_APP_BASE_URL}/user/check_test_attempt?test_id=${test_id}&attempt=first`, {
            method:"get",
              headers: {
                  'Content-Type': 'application/json',
                  "Authorization": "Bearer "+token,
                  // Other headers if needed
              },
          })
          .then(response => response.json())
          .then(result =>  {
           
            setCheckAttempt(result)
          })
          .catch(error => console.log(error));
      } catch (error) {
          console.error("Error fetching test Check Attempt First:", error);
      }

              //   '''''''''''''''''''check attempt Second''''''''''''''''''''''''
    try {
        fetch(`${process.env.REACT_APP_BASE_URL}/user/check_test_attempt?test_id=${test_id}&attempt=second`, {
          method:"get",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+token,
                // Other headers if needed
            },
        })
        .then(response => response.json())
        .then(result => setCheckSecAttempt(result))
        .catch(error => console.log(error));
    } catch (error) {
        console.error("Error fetching test Check Attempt Second:", error);
    }
    //   '''''''''''''''''''check attempt Third''''''''''''''''''''''''
    try {
        fetch(`${process.env.REACT_APP_BASE_URL}/user/check_test_attempt?test_id=${test_id}&attempt=third`, {
          method:"get",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+token,
                // Other headers if needed
            },
        })
        .then(response => response.json())
        .then(result => setCheckThirdAttempt(result))
        .catch(error => console.log(error));
    } catch (error) {
        console.error("Error fetching test Check Attempt Third:", error);
    }
      } else {
          Navigate('/login');
      }

     


    }, [test_id]);

    const handlesecondattempt = async () => {
      const token = localStorage.getItem('token'); 
      const id = resultList.test_marks._id;
      const optional_section = resultList.test_marks.optional_section;
      const timeduration = resultList.test_marks.test_id.total_duration;
     
      if (token) {
        Navigate('/startsecond/' + id+"/"+test_id+"/"+optional_section+"/"+timeduration);
     }else{
      Navigate('/login');
     }
    }


    const handlethirdattempt = async () => {
      const token = localStorage.getItem('token'); 
      const id = resultSecList.test_marks._id;
      const optional_section = resultSecList.test_marks.optional_section._id;
      const timeduration = resultSecList.test_marks.test_id.total_duration;
     
      if (token) {
        Navigate('/startthird/' + id+"/"+test_id+"/"+optional_section+"/"+timeduration);
     }else{
      Navigate('/login');
     }
    }


    const handlefirstondattemptsolution = async () => {
      const token = localStorage.getItem('token'); 
      const id = resultList.test_marks._id;
      if (token) {
        Navigate('/firstsolution/'+id);
     }else{
      Navigate('/login');
     }
    }

    const handlesecondattemptsolution = async () => {
      const token = localStorage.getItem('token'); 
      const id = resultSecList.test_marks._id;
      if (token) {
        Navigate('/secondsolution/'+id);
     }else{
      Navigate('/login');
     }
    }

    const handlethirdattemptsolution = async () => {
      const token = localStorage.getItem('token'); 
      const id = resultThirdList.test_marks._id;
      if (token) {
        Navigate('/thirdsolution/'+id);
     }else{
      Navigate('/login');
     }
    }



    const handleleaderboard = async () =>{
      const token = localStorage.getItem('token'); 
      if (token) {
        Navigate('/leaderboard/' + test_id);
     }else{
      Navigate('/login');
     }
    }

    
    // console.log("result 3", resultThirdList);
  return(
    <>
      {/* <Header /> */}
      <img className='srimg' src="abt.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section className="cc">
    <div className="container">
      
      <div className="row">
        <div className="col-md-8">
        <h3 style={{ color: "#2e1b1d" }}>{ resultList?.test_marks.test_id.title }</h3>
      <hr style={{ border: "1px solid #f27e18" }} />
        <p className='text-danger'>QUICK NOTE</p>
          <p style={{ textAlign: "justify" }}>
            <ul>
                <li>Student can attempt one maximum three times</li>
                <li>Each time they will get new set of questions which they left on previous attempt or markes wrong</li>
            </ul>
          </p>
         
        </div>
        <div className="col-md-4">
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${resultList?.test_marks.test_id.image}`}
            width="100%"
            style={{ borderRadius: 20 }}
          />
        </div>
      </div>
    </div>
  </section>
  <section className="cons" id="counter"style={{
      background: "url(dfddfvbds.png)",
      backgroundAttachment: "fixed",
      backgroundSize: "cover"
    }}>
    <div className="container">
      <h3 style={{ color: "#2e1b1d" }}>First Attempt : &amp; Quick Test Summary</h3>
      <hr style={{ border: "1px solid #f27e18" }} />
      <div className="row">
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={19740}>
                {rank?.leader_board.rank}
                </span>
               
              </div>
            </h3>
            <h5 className='text-light'>Rank</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                 {resultList?.test_marks?.total_marks}/{ resultList?.test_marks.test_id?.total_question * resultList?.test_marks.test_id.per_question_mark}
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Score</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultList?.test_marks.total_correct_ans}/{resultList?.test_marks.test_id.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Correct Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultList?.test_marks.total_incorrect_ans}/{resultList?.test_marks.test_id.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Wrong Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={598}>
                {resultList?.test_marks.total_attempted}/{resultList?.test_marks.test_id.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Attempted</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={50}>
                {resultList?.test_marks.total_unattempted}/{resultList?.test_marks.test_id.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Unattempted</h5>
          </center>
        </div>
      </div>
    </div>
  </section>
  <section className="cc">
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-4"> <button class="btn btn-xl btn-info form-control text-uppercase" onClick={handlefirstondattemptsolution} type="button">View Test Solution</button> </div>
        <div className="col-md-12"><button class="btn btn-xl btn-warning form-control text-uppercase" onClick={handleleaderboard} type="button">View LeaderBoard</button></div>
      </div>
    </div>
  </section>





    {/* '''''''''''''''''''''''''''''''''''''''second attempt''''''''''''''''''''''''''''''''''''''''''''''''''''''''' */}
    
    <section className="cons" id="counter"style={{
      background: "url(dfddfvbds.png)",
      backgroundAttachment: "fixed",
      backgroundSize: "cover"
    }}>
    <div className="container">
      <h3 style={{ color: "#2e1b1d" }}>Second Attempt : &amp; Quick Test Summary   {checkSecAttempt?.status && checkSecAttempt?.message === "not_attempted" && checkThirdAttempt?.message === "not_attempted"? ( <button className='btn btn-sm btn-primary' onClick={handlesecondattempt}>Second Attempt Exam</button> )  : ("")}</h3>
      <hr style={{ border: "1px solid #f27e18" }} />
      <div className="row">
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={19740}>
                {rank?.leader_board.rank}
                </span>
               
              </div>
            </h3>
            <h5 className='text-light'>Rank</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultSecList?.test_marks?.total_marks}/{ resultSecList?.test_marks?.test_id?.total_question * resultSecList?.test_marks?.test_id?.per_question_mark}
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Score</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultSecList?.test_marks?.total_correct_ans}/{resultSecList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Correct Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultSecList?.test_marks?.total_incorrect_ans}/{resultSecList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Wrong Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={598}>
                {resultSecList?.test_marks?.total_attempted}/{resultSecList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Attempted</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={50}>
                {resultSecList?.test_marks?.total_unattempted}/{resultSecList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Unattempted</h5>
          </center>
        </div>
      </div>
    </div>
  </section>
  <section className="cc">
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-4"> <button class="btn btn-xl btn-info form-control text-uppercase" onClick={handlesecondattemptsolution} type="button">View Test Solution</button> </div>
      </div>
    </div>
  </section>

  {/* '''''''''''''''''''''''''''''''''''''''''Third Attempt''''''''''''''''''''''''''''''''''''''''''''''''''' */}
    
  <section className="cons" id="counter"style={{
      background: "url(dfddfvbds.png)",
      backgroundAttachment: "fixed",
      backgroundSize: "cover"
    }}>
    <div className="container">
      <h3 style={{ color: "#2e1b1d" }}>Third Attempt : &amp; Quick Test Summary  {checkSecAttempt?.status && checkSecAttempt?.message === "attempted" && checkThirdAttempt?.message === "not_attempted"? ( <button className='btn btn-sm btn-primary' onClick={handlethirdattempt}>Third Attempt Exam</button> )  : ("")} </h3>
      <hr style={{ border: "1px solid #f27e18" }} />
      <div className="row">
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={19740}>
                {rank?.leader_board.rank}
                </span>
               
              </div>
            </h3>
            <h5 className='text-light'>Rank</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultThirdList?.test_marks?.total_marks}/{ resultThirdList?.test_marks?.test_id?.total_question * resultThirdList?.test_marks?.test_id?.per_question_mark}
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Score</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultThirdList?.test_marks?.total_correct_ans}/{resultThirdList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Correct Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={289}>
                {resultThirdList?.test_marks?.total_incorrect_ans}/{resultThirdList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Wrong Answers</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={598}>
                {resultThirdList?.test_marks?.total_attempted}/{resultThirdList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Attempted</h5>
          </center>
        </div>
        <div className="col-md-2 col-12">
          <center>
            <h3 style={{ fontSize: 15}} className='text-warning text-bold'>
              <div className="NumCounter">
                <span className="counter-value" data-count={50}>
                {resultThirdList?.test_marks?.total_unattempted}/{resultThirdList?.test_marks?.test_id?.total_question }
                </span>
              </div>
            </h3>
            <h5 className='text-light'>Total Unattempted</h5>
          </center>
        </div>
      </div>
    </div>
  </section>
  <section className="cc">
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-4"> <button class="btn btn-xl btn-info form-control text-uppercase" onClick={handlethirdattemptsolution} type="button">View Test Solution</button> </div>
      </div>
    </div>
  </section>

  <section className="cc">
    <div className="container">
      <div className="row">
        <div className="col-md-12 mb-4"><hr style={{ border: "1px solid #f27e18" }} /> <a href="/" class="btn btn-xl btn-secondary form-control text-uppercase" >Go To Home</a> </div>
      </div>
    </div>
  </section>


      {/* <Footer /> */}
    </>
  )
}