// import Header from '../../../../components/header';
// import Footer from '../../../../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// import { useNavigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
export default function Contact() {
  const navigate = useNavigate();

  const [sectionList, setSectionList] = useState([]);

  const [answerList, setAnswerList] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  let [total_not_visited, set_total_not_visited] = useState(0)
  let [total_attempted, set_total_attempted] = useState(0)
  let [total_not_attempted, set_total_not_attempted] = useState(0)
  let [total_marked_for_review, set_total_marked_for_review] = useState(0)
  let [total_ans_and_mark_for_review, set_total_ans_and_mark_for_review] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [questionidd, setQuestionidd] = useState(null);

  var initialAnswerList = [];




  const [questionList, setQuestionList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const { id,test_id, selectedValue, timeduration } = useParams();
  const [viewableItems, setViewableItems] = useState([]);
  const [minutes, setMinutes] = useState(timeduration);
  const [seconds, setSeconds] = useState(0);
  var access_token='';
  const formatTime = (value) => (value < 10 ? `0${value}` : `${value}`);
  useEffect(() => {

    fetchTestSectionDetails();



    const fetchData = async () => {
      try {
        const sectionDetails = await fetchTestSectionDetails();

        if (sectionDetails.length > 0) {
          const firstSectionType = sectionDetails[0].test_section_id._id;
          fetchTestQuestionDetails(firstSectionType);
          setSelectedSection(firstSectionType);
          // console.log("selected Section", firstSectionType)

          // fetchallquestiondetails(sectionDetails[0].test_id)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };



    // Call fetchData function
    fetchData();




    fetchallquestiondetails();
    
  }, []);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
        // Call your log function when the timer reaches 0
        handlefinalSave()
        return;
    }
    const timerInterval = setInterval(() => {
        if (seconds > 0) {
            setSeconds(seconds - 1);
        } else {
            if (minutes > 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on component unmount

}, [minutes, seconds]);


  const handleRadioButtonPress = (questionId, value) => {
    const data = {
      questionId: questionId,
      answer: value
    }
    setSelectedOptions(data);
  };
  const getq_id = (questionId) => {
    // alert(questionId)
    const data = {
      questionId: questionId
    }
    setQuestionidd(data);
  }



  const onViewableItemsChanged = ({ viewableItems }) => {
    setViewableItems(viewableItems);
    viewableItems.forEach((visibleItem) => {
      const questionId = visibleItem.item.question_id._id;
      const selectedOptionsForQuestion = selectedOptions[questionId] || [];
      if (selectedOptionsForQuestion.length === 0) {
        // Update answerList to set type to 'unattempted'
        setAnswerList((prevAnswerList) => {
          const updatedAnswerList = prevAnswerList.map((answer) => {
            if (answer.question_id === questionId) {
              return {
                ...answer,
                type: 'unattempted',
              };
            }
            return answer;
          });
          return updatedAnswerList;
        });
      }
    });
  };



  const handleInputChangeTest = (index, field, value) => {
    setQuestionsList((prevList) => {
      const updatedList = [...prevList];
      const question = updatedList[index] || {};

      // console.log(field);
      if (field === 'question_image' || field === 'option_one_image' || field === 'option_two_image' || field === 'option_two_image' || field === 'option_three_image' || field === 'option_four_image' || field === 'option_five_image' || field === 'option_six_image' || field === 'option_seven_image' || field === 'option_eight_image' || field === 'option_eight_image' || field === 'option_nine_image' || field === 'option_ten_image' || field === 'suggestion_image') {
        const file = value;
        question[field] = file

      } else {
        question[field] = value;
      }
      //   question[field] = value;
      updatedList[index] = question;
      return updatedList;
    });
  };

  const fetchTestSectionDetails = async () => {
    try {
      console.log("PQWE",selectedValue);
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${test_id}`);
      const trueOptions = response.data.test_section_details.filter(item => item.is_optional === true);
      const filteredData = trueOptions.filter(item => item._id === selectedValue);
      const trueOptionsfalse = response.data.test_section_details.filter(item => item.is_optional === false);
      const mergedArray = trueOptionsfalse.concat(filteredData);

      setSectionList(mergedArray);

      return mergedArray;

    } catch (error) {
      console.error("Error fetching test section details:", error);
      return [];
    }
  };



  const fetchTestQuestionDetails = async (selectedSectionId) => {
    try {
        const authToken = localStorage.getItem('token'); 
    //   const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_second_attempt_test_questions?exam_result_main_id=${id}`);
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/get_third_attempt_test_questions?exam_result_main_id=${id}`,
        {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    );

    // Handle the response
    console.log(response.data);
      console.log("raju",response.data)
      const filteredQuestions = response.data.questions.filter(item => {
        return item.test_section_detail_id.test_section_id._id === selectedSectionId;
      });
      setQuestionList(filteredQuestions);
      console.log(filteredQuestions);
    } catch (error) {
      console.error("Error fetching test question details:", error);
    }
  };


  const handleSaveButtonPress = () => {
    // Update answerList based on selectedOptions for currently visible items
    let vb = document.querySelector('.active').closest('div').querySelector('input').value;

    // const selectedOptionsForQuestion = answerList.find((item) => item.question_id === selectedOptions.questionId);


    let new_ans = []
    if (selectedOptions?.answer.length > 0) {
      new_ans.push(selectedOptions.answer)
    } else {
      const datas = null
      new_ans.push(datas)
    }

    const updatedAnswerList = answerList.map((answer) => {
      // Check if selectedOptions is an array before using some()
      if (vb === answer.question_id) {
        //  const selectedOptionsForQuestion = selectedOptions.find((item) => item.question_id === answer.question_id);

        return {
          ...answer,
          answers: new_ans,
          type: new_ans > 0 ? 'attempted' : 'unattempted',
        };
      }

      return answer; // Return unchanged answer for non-visible items
    });

    setAnswerList(updatedAnswerList);
    setSelectedOptions(null)

  }

  const handleMarkedforReviewButtonPress = () => {
    // Update answerList based on selectedOptions for currently visible items
    let vb = document.querySelector('.active').closest('div').querySelector('input').value;

    // const selectedOptionsForQuestion = answerList.find((item) => item.question_id === selectedOptions.questionId);


    let new_ans = []
    if (selectedOptions?.answer.length > 0) {
      new_ans.push(selectedOptions.answer)
    } else {
      const datas = null



      new_ans.push(datas)
    }

    const updatedAnswerList = answerList.map((answer) => {
      // Check if selectedOptions is an array before using some()
      if (vb === answer.question_id) {
        //  const selectedOptionsForQuestion = selectedOptions.find((item) => item.question_id === answer.question_id);

        return {
          ...answer,
          answers: new_ans,
          type: new_ans > 0 ? 'review_with_attempted' : 'review_without_attempted',
        };
      }

      return answer; // Return unchanged answer for non-visible items
    });
    // console.log('check final', updatedAnswerList);
    setAnswerList(updatedAnswerList);
    setSelectedOptions(null)
  }



  const fetchallquestiondetails = async () => {
    try {
      const sectionDetails = await fetchTestSectionDetails();
      // console.log("Pankaj",sectionDetails);


      const authToken = localStorage.getItem('token'); 
      //   const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_second_attempt_test_questions?exam_result_main_id=${id}`);
      const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/get_third_attempt_test_questions?exam_result_main_id=${id}`,
          {
              headers: {
                  Authorization: `Bearer ${authToken}`
              }
          }
      );
      const totalanslist = response.data.questions;
      // console.log("Pankaj2",totalanslist);
      const similerValue = totalanslist.filter(answer => {
        const similar = sectionDetails.some(section => {
          const sectionTestId = section.test_section_id ? section.test_section_id._id : null;
          const answerTestId = answer.test_section_detail_id.test_section_id ? answer.test_section_detail_id.test_section_id._id : null;
          return sectionTestId === answerTestId;
        });
        return similar;
      });
      // console.log("Pankaj3",similerValue);
      initialAnswerList = similerValue.map((question, index) => {
        return {
          question_id: question.question_id._id, // Assuming question has a unique identifier
          answers: [],
          type: 'unseen',
          section_id: question.test_section_detail_id,
          question_no: question.question_no
        };
      });
      console.log("Pankaj4",initialAnswerList);
      setAnswerList(initialAnswerList);
      // console.log("Similar sss:", initialAnswerList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  



  const handlefinalSave = async () => {
    try{

      
      const test_ids = test_id;
      const time_takes =timeduration-minutes;
      const optional_section  = selectedValue;
      const userData = {
        test_id: test_id,
        time_taken:time_takes,
        answer_list:answerList,
        optional_section: optional_section,
        attempt:"third"
    };
    console.log("stringssss", userData);
    const numberArray1 = JSON.stringify(userData);
    console.log("string", numberArray1);
    const authToken = localStorage.getItem('token'); 
    console.log("authontication", authToken);
    const result = await fetch(`${process.env.REACT_APP_BASE_URL}/user/submit_test`, {
    
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
          "Authorization":"Bearer "+authToken,
          // Other headers if needed
      },
      body: numberArray1,
  })
    const data = await result.json();
  // console.log(data);
  if (data.status) {
    navigate('/finalresult/'+test_id); 
  }
      
      console.log("kaa ho ",data);
    }catch (error) {
      console.error("Error fetching data:", error);
    }
    
  }






 











  const handleTabClick = (selectedSectionId) => {
    fetchTestQuestionDetails(selectedSectionId);
    setSelectedSection(selectedSectionId);
  };

  return (
    <>
      <div id="fullscreenElement">
        <section className="aaa">
          <div className="container">
            <div className="row">
              <div className="col-md-1">
                <img src="../../../../a1.png" width="50%" />
              </div>
              <div className="col-md-10">
                <h5 className="text-center hh5">Railway Recruitment Boards</h5>
                <h5 className="text-center hh6">Railway Recruitment Boards</h5>
              </div>
              <div className="col-md-1">
                <img src="../../../../a1.png" width="50%" />
              </div>
            </div>
          </div>
        </section>
        <section className="b">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">Railway Recruitment Boards</p>
              </div>
              <div className="col-md-2">
                <div className="row justify-content-end">
                  <div className="col-md-3">
                    <img src="../../../../t1.png" width="100%" />
                  </div>
                  <div className="col-md-9">
                    <p className="pt-2">Question Paper</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row justify-content-end">
                  <div className="col-md-3">
                    <img src="../../../../t1.png" width="100%" />
                  </div>
                  <div className="col-md-9">
                    <p className="pt-2">View Instructions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="a">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-9 cc9"
                style={{ border: "1px solid #000", background: "#e3f7fd" }}
              >
                <div
                  className="row"
                  style={{ border: "1px solid black", background: "#fff" }}
                >
                  <div className="col-md-6">
                    <span>Session</span>
                  </div>
                  <div className="col-md-6 text-right">
                    <span>Time Left : {`${formatTime(minutes)}:${formatTime(seconds)}`}</span>
                  </div>
                </div>
                <div
                  className="row pt-1 pb-1"
                  style={{ border: "1px solid black", background: "#ededed" }}
                >
                  <div className="col-md-12">
                    {sectionList && sectionList?.map((items, index) => (
                      <span className="btn btn-primary btn-sm m-1" onClick={() => handleTabClick(items.test_section_id._id)}>{items.test_section_id.name}</span>
                    ))}

                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    border: "1px solid black",
                    background: "#4d83c3",
                    color: "#fff"
                  }}
                >
                  <div className="col-md-6">
                    Question Type : Multiple Choice Questions
                  </div>
                  <div className="col-md-6 text-right">
                    <div id="google_translate_element" />
                  </div>
                </div>
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide carousel-parent"
                  data-ride="carousel"
                  data-pause="false"
                  data-interval="false"
                >
                  {/* overflow: auto; */}
                  <div className="carousel-inner ttt">
                    {questionList && questionList?.map((que, index) => (
                      <div className={`carousel-item  pt-2 ${index === activeQuestion ? "active" : ""}`} key={index} >
                        <h5> Question No {que.question_no}</h5>
                        <hr style={{ border: "1px solid #000" }} />
                        <div className="cst">
                          <p>{que.question_id.question} </p>
                          <input type="hidden" id="getqid" value={que.question_id._id} />
                          <input type="hidden" id="getqid" value={que.question_id._id} />
                          {/* checked={answerList.find(answer => answer.question_id === que.question_id._id && answer.answers.includes("1"))} */}
                          <p><input type='radio' name='option_' checked={answerList.find(answer => answer.question_id === que.question_id._id && answer.answers.includes("1"))} className='ques_option' onChange={(value) => handleRadioButtonPress(que.question_id._id, "1")} value={1} /> {que.question_id.option_one}</p>
                          <p ><input type='radio' name='option_' checked={answerList.find(answer => answer.question_id === que.question_id._id && answer.answers.includes("2"))} class='ques_option' onChange={(value) => handleRadioButtonPress(que.question_id._id, "2")} value={2} /> {que.question_id.option_two}</p>
                          <p ><input type='radio' name='option_' checked={answerList.find(answer => answer.question_id === que.question_id._id && answer.answers.includes("3"))} class='ques_option' onChange={(value) => handleRadioButtonPress(que.question_id._id, "3")} value={3} />  {que.question_id.option_three}</p>
                          <p ><input type='radio' name='option_' checked={answerList.find(answer => answer.question_id === que.question_id._id && answer.answers.includes("4"))} class='ques_option' onChange={(value) => handleRadioButtonPress(que.question_id._id, "4")} value={4} /> {que.question_id.option_four}</p>

                        </div>
                      </div>
                    ))}


                  </div>
                </div>

                <div className="row rr1">
                  <div className="col-lg-4 col-md-6 col-12">
                    <p className="btn btn-warning btn-block"
                      data-target="#carouselExampleIndicators"
                      data-slide="next"
                      onClick={handleMarkedforReviewButtonPress}

                    >
                      Marked For Review &amp; Next
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="btn btn-primary btn-block" >Clear Response</p>
                  </div>
                  <div
                    className="col-lg-4 col-md-3 col-12"
                    style={{ textAlign: "end" }}
                  >
                    <p
                      data-target="#carouselExampleIndicators"
                      data-slide="next"
                      className="btn btn-success"
                      // onClick={getq_id}
                      onClick={handleSaveButtonPress}
                    >
                      Save &amp; Next
                    </p>
                    {/* <Button className="my-4" color="primary" type="button" >Save</Button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div style={{ border: "1px solid black", padding: 15 }}>
                  <div className="row">
                    <div className="col-md-3">
                      <img src="../../../../t1.png" width="100%" />
                    </div>
                    <div className="col-md-9">
                      <h2 className="pt-3 pankajj">Ramesh</h2>
                    </div>
                  </div>
                </div>
                <div
                  style={{ border: "1px solid black", padding: 25 }}
                  className="A11"
                >
                  <div className="row mt-1">
                    <div className="col-md-2" style={{ padding: 0 }}>
                      <img src="../../../../a3.png" width="100%" />
                    </div>
                    <div className="col-md-4">
                      <h5 className="pt-3">Answered</h5>
                    </div>
                    <div className="col-md-2" style={{ padding: 0 }}>
                      <img src="../../../../a2.png" width="100%" />
                    </div>
                    <div className="col-md-4">
                      <h5 className="pt-3">Not Answered</h5>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-2" style={{ padding: 0 }}>
                      <img src="../../../../a4.png" width="100%" />
                    </div>
                    <div className="col-md-4">
                      <h5 className="pt-3">Not Visited</h5>
                    </div>
                    <div className="col-md-2" style={{ padding: 0 }}>
                      <img src="../../../../a5.png" width="100%" />
                    </div>
                    <div className="col-md-4">
                      <h5 className="pt-3">Marked For Review</h5>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-2" style={{ padding: 0 }}>
                      <img src="../../../../a6.png" width="100%" />
                    </div>
                    <div className="col-md-10">
                      <h5 className="pt-3">
                        Answered &amp; Marked For Review ( Answered &amp; Marked For
                        Review )
                      </h5>
                    </div>
                  </div>
                </div>
               
                <div className="A22 cstt">
                  <div className="row justify-content-center">
                    {answerList && answerList?.map((que, index) => {
                      let background = '';
                      if (que.type === 'unseen') {
                        background = 'background1';
                      } else if (que.type === 'unattempted') {
                        background = 'background2';
                      } else if (que.type === 'attempted') {
                        background = 'background3';
                      } else if (que.type === 'review_without_attempted') {
                        background = 'background4';
                      } else if (que.type === 'review_with_attempted') {
                        background = 'background5';
                      }
                      return (
                        <>
                          <div className="col-md-3 col-12 mt-2 paad">
                            <center>
                              <span
                                className={`how btn ${background} `}
                                // className={`how btn background1 11 ${index === activeQuestion ? "active" : ""}`}
                                data-target="#carouselExampleIndicators"
                                data-slide-to={index}
                              >
                                {index + 1}
                              </span>
                            </center>

                          
                            
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>

                <center style={{ background: "#ededed", border: "1px solid black" }}>

                  <span className="btn btn-success mt-2 mb-2" onClick={handlefinalSave}>Submit</span>
                </center>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}