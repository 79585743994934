import pic1 from "../images/01.png"
export default function footer(){
    return(
      <>
       <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-12 pb-3'>
            <img src={pic1} className="pt-3 pb-3" alt="sarkariexam" />
              {/* <img className='pt-3 pb-3' src='01.png' alt='sarkariexam' /> */}
            </div>
            <div className='col-md-4 pb-3'>
              <h5 style={{textAlign:"justify"}}>Practicing constantly with the Pensbook exams avoids this exam stress, and students will get used to the exam pattern. Since they already know the exam structure by practicing many times, the final exam will be more comfortable for them and 5k student Registered.</h5>
            </div>
            <div className='col-md-4 pb-3'>
              
              <div className='row'>
                <div className="col-md-12"><h4 style={{ textAlign:"center", fontWeight: "700", color: "#fff" }}>Products</h4></div>
                <div className='col-md-6 col-6'>
                  <a href="/"><h5>Home</h5></a>
                  <a href="/Testseries"><h5>Tests Series</h5></a>
                  <a href="/Videos"><h5>YouTube Video</h5></a>
                  <a href="/Contact"><h5>Contact Us</h5></a>
                  <a href="/blog"><h5>Blog</h5></a>
                </div>
                <div className='col-md-6 col-6 text-right'>
                 
                  <a href="/privacy"><h5>Refer & Earn</h5></a>
                  <a href="/perdayJanakari"><h5>Books</h5></a>
                  <a href="/exams"><h5>Exams</h5></a>
                  <a href="/Privacy"><h5>Privacy Policy</h5></a>
                  <a href="/term"><h5>Term & Condition</h5></a>
                </div>
              </div>

            </div>
            <div className='col-md-4'>
              <h4 style={{ fontWeight: "700", color: "#fff" }}>Location</h4>
              <h5>Meethapur Bypass Bhupattipur, Near Agriculture University, Patna, Bihar 800020</h5>
              <h5>Email : info@pensbook.com</h5>
              <h5>Helpline : 0612-2201849/ +91- 9431421849</h5>
            </div>
          </div>
        </div>
      </footer>
      <section className='copyy'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-6">
              <p style={{ color: "#fff", textAlign: "center" }} className="mb-1 mt-1">
                Copyrights 2024 <a style={{ fontWeight: "700", color: "#fff" }} href="/">Pensbook</a>. All Rights Reserved.
              </p>
            </div>
            {/* <div className="col-md-6">
              <p style={{ color: "#fff", textAlign: "center" }} className="mb-1 mt-1">
                Designed By{" "}
                <a style={{ fontWeight: "700", color: "#fff" }} href="https://www.brightcodess.com/">Brightcode Software Services.</a>
              </p>
            </div> */}
          </div>
        </div>
      </section>
      </>
    )
}