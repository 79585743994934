

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';

// const SectionalDetails = () => {
//     const [selectedPaper, setSelectedPaper] = useState('');
//     const [sectionDetails, setSectionDetails] = useState([]);
//     const { testId } = useParams();
//     const location = useLocation();

//     useEffect(() => {
//         setSelectedPaper(location.state.selectedPaper); // Set selected paper from location state
//         fetchSectionDetails();
//     }, [testId, location.state.selectedPaper]);

//     const fetchSectionDetails = async () => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             console.log(response, "section")
//             setSectionDetails(response.data.test_section_details);
//         } catch (error) {
//             console.error('Error fetching section details:', error);
//         }
//     };

//     return (
//         <div>
//             <h1>Sectional Details</h1>
           
//             <div>
//     {/* Render buttons only for the "Math" section */}
//     {sectionDetails
//         .filter(section => section.test_section_id.name === "Math" || section.test_section_id.name === selectedPaper)
//         .map((section, index) => (
//             <button
//                 key={section._id}
//                 style={{
//                     backgroundColor: section.test_section_id.name === "Math" ? 'blue' : '', 
//                     color: section.test_section_id.name === "Math" ? 'white' : '', 
//                     margin: '5px', 
//                     padding: '5px 10px', 
//                     border: 'none', 
//                     borderRadius: '5px' 
//                 }}
//             >
//                 {section.test_section_id.name} {/* Show the name instead of ID */}
//             </button>
//     ))}
    
// </div>


//         </div>
//     );
// };

// export default SectionalDetails;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';

// const SectionalDetails = () => {
//     const [selectedPaper, setSelectedPaper] = useState('');
//     const [sectionDetails, setSectionDetails] = useState([]);
//     const [questions, setQuestions] = useState([]);
//     const { testId } = useParams();
//     const location = useLocation();

//     useEffect(() => {
//         // Set selected paper from location state
//         setSelectedPaper(location.state.selectedPaper);
//         fetchSectionDetails();
//     }, [testId, location.state.selectedPaper]);

//     const fetchSectionDetails = async () => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
            
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             // Filter section details to include both selected and other test section IDs
//             const selectedPaperId = location.state.selectedPaper;
//             console.log("Selected Paper ID:", selectedPaperId); // Log selected paper ID for debugging
//             const filteredSectionDetails = response.data.test_section_details.filter(section => {
//                 // Include sections with the selected paper ID or sections that are not optional
//                 return section.test_section_id._id === selectedPaperId || !section.is_optional;
//             });
//             console.log("Filtered Section Details:", filteredSectionDetails);

//             if (filteredSectionDetails.length > 0) {
//                 const firstSectionId = filteredSectionDetails[0].test_section_id._id;
//                 fetchQuestionsByTest(testId, firstSectionId);
//             }

//             setSectionDetails(filteredSectionDetails);
//         } catch (error) {
//             console.error('Error fetching section details:', error.message);
//         }
//     };

//     const fetchQuestionsByTest = async (testId, sectionId) => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_question_by_test?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             console.log(response.data.questions); // Log the questions data for debugging

//             // Filter questions based on test_id and test_section_id
//             const filteredQuestions = response.data.questions.filter(question => {
//                 return question.test_id === testId && question.test_section_detail_id.test_section_id._id === sectionId;
//             });

//             console.log(filteredQuestions,"question")

//             setQuestions(filteredQuestions);
//         } catch (error) {
//             console.error('Error fetching questions:', error);
//         }
//     };

//     const handleSectionClick = (sectionId) => {
//         setSelectedPaper(sectionId); // Update selected paper with the sectionId
//         fetchQuestionsByTest(testId, sectionId); // Fetch questions for the selected section
//     };
    

//     return (
//         <div>
//             <h1>Sectional Details</h1>
//             <div>
//                 {/* Render buttons for each section */}
//                 {sectionDetails.map((section, index) => (
//                     <button
//                         key={section._id}
//                         style={{
//                             backgroundColor: section.test_section_id._id === selectedPaper ? 'blue' : '', 
//                             color: section.test_section_id._id === selectedPaper ? 'white' : '', 
//                             margin: '5px', 
//                             padding: '5px 10px', 
//                             border: 'none', 
//                             borderRadius: '5px' 
//                         }}
//                         onClick={() => handleSectionClick(section.test_section_id._id)}
//                     >
//                         {section.test_section_id.name}
//                     </button>
//                 ))}
//             </div>
//             <div>
//                 {/* Display questions */}
//                 <h2>Questions</h2>
//                 <ul>
//                     {/* {questions.map((question, index) => (
//                         <li key={question._id}>{question.question_id.question}</li>
//                     ))} */}
// {questions && questions.length > 0 ? (
//     questions.map((question, index) => (
//         <li key={question._id}>
//             <div>
//                 Question {question.question_id.questionNo}: {question.question_id.question}
//             </div>
//             <ul>
//                 <li>Option 1: {question.question_id.option_one}</li>
//                 <li>Option 2: {question.question_id.option_two}</li>
//                 <li>Option 3: {question.question_id.option_three}</li>
//                 <li>Option 4: {question.question_id.option_four}</li>
//                 <li>Option 5: {question.question_id.option_five}</li>
//             </ul>
//         </li>
//     ))
// ) : (
//     <li>No questions found</li>
// )}



//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default SectionalDetails;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import user from '../../images/newuser.png'
import answered from '../../images/Asset 5.png'
import notvisited from '../../images/Asset 1.png'
import notAnswered from '../../images/Asset 6.png'
import markforReview from '../../images/Asset 2.png'
import answeredmarkforReview from '../../images/review_att.png'

const SectionalDetails = () => {
    const location = useLocation(); 
    const [questionStatus, setQuestionStatus] = useState([]);
    const [currentQuestionIndexForChange, setCurrentQuestionIndexForChange] = useState(0);

    const totalDuration = location.state ? location.state.totalDuration : null;
    const [currentTime, setCurrentTime] = useState(totalDuration * 60); // Convert seconds to milliseconds
    const selectedLanguage = location.state ? location.state.selectedLanguage : null;
  
    const [numberedButtonClicked, setNumberedButtonClicked] = useState(false);

    const [selectedPaper, setSelectedPaper] = useState('');
    const [sectionDetails, setSectionDetails] = useState([]);
    const [clickedNumberedButton, setClickedNumberedButton] = useState(false);
    const [questions, setQuestions] = useState([]);
    const { testId } = useParams();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    
    const [selectedOptions, setSelectedOptions] = useState({});
    const [saveNextClicked, setSaveNextClicked] = useState(false); // State to track if "Save & Next" button was clicked

    // useEffect(() => {
    //     setCurrentQuestionIndexForChange(currentQuestionIndexForChange);
    // }, [currentQuestionIndexForChange]);
    

    useEffect(() => {
        setSelectedPaper(location.state.selectedPaper);
        fetchSectionDetails();
    }, [testId, location.state.selectedPaper]);

    useEffect(() => {
        if (questions.length > 0) {
            setQuestionStatus(Array(questions.length).fill('notVisited'));
        }
    }, [questions]);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentTime > 0) {
                setCurrentTime(prevTime => prevTime - 1);
            } else {
                setCurrentTime(0); // Prevent negative values
            }
        }, 1000);
    
        // Clear the timeout when the component unmounts or when time reaches 0
        return () => clearTimeout(timer);
    }, [currentTime]);
    

    // Calculate remaining time
    const remainingHours = Math.floor(currentTime / 3600);
    const remainingMinutes = Math.floor((currentTime % 3600) / 60);
    const remainingSeconds = Math.floor((currentTime % 60));

    // Format remaining time
    const formattedTime = `${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
    
    
    const fetchSectionDetails = async () => {
        try {
            const authToken = localStorage.getItem('token');
            if (!authToken) {
                console.error('Authentication token not found');
                return;
            }
            
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${testId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            const selectedPaperId = location.state.selectedPaper;
            const filteredSectionDetails = response.data.test_section_details.filter(section => {
                return section.test_section_id._id === selectedPaperId || !section.is_optional;
            });

            if (filteredSectionDetails.length > 0) {
                const firstSectionId = filteredSectionDetails[0].test_section_id._id;
                fetchQuestionsByTest(testId, firstSectionId);
            }

            setSectionDetails(filteredSectionDetails);
        } catch (error) {
            console.error('Error fetching section details:', error.message);
        }
    };

    const fetchQuestionsByTest = async (testId, sectionId) => {
        try {
            const authToken = localStorage.getItem('token');
            if (!authToken) {
                console.error('Authentication token not found');
                return;
            }
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_question_by_test?test_id=${testId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
    
            const filteredQuestions = response.data.questions.filter(question => {
                return question.test_id === testId && question.test_section_detail_id.test_section_id._id === sectionId;
            });
    
            // Initialize selected options for each question with empty arrays
            const initialSelectedOptions = {};
            filteredQuestions.forEach(question => {
                initialSelectedOptions[question._id] = [];
            });
    
            setSelectedOptions(initialSelectedOptions);
            setQuestions(filteredQuestions);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };
    
    const handleSectionClick = (sectionId) => {
        setSelectedPaper(sectionId);
        if (sectionDetails.find(section => section.test_section_id._id === sectionId)?.is_optional) {
            setCurrentQuestionIndex(0); // Set current question index to 0 if the selected section is optional
            
        }
        fetchQuestionsByTest(testId, sectionId);
    };
    
    const handleOptionChange = (questionId, optionKey) => {
        setSelectedOptions(prevOptions => {
            const newOptions = { ...prevOptions };
            // Toggle the selection of the option
            if (newOptions[questionId]?.includes(optionKey)) {
                newOptions[questionId] = newOptions[questionId].filter(selectedKey => selectedKey !== optionKey);
            } else {
                newOptions[questionId] = [...(newOptions[questionId] || []), optionKey];
            }

         if (newOptions[questionId].length === 0) {
            updateQuestionStatus(currentQuestionIndex, 'notAnswered');
        } else if (questionStatus[currentQuestionIndex] === 'markedForReview') {
            updateQuestionStatus(currentQuestionIndex, 'answeredMarkedReview');
        } else {
            updateQuestionStatus(currentQuestionIndex, 'answered');
        }
           // updateQuestionStatus(currentQuestionIndex, 'answered'); 
            return newOptions;
        });
    };
    const handleMarkForReview = (index) => {
        // Logic to mark the question for review
        console.log('Marked for review:', index);
       // updateQuestionStatus(index, 'markedForReview');
       if (selectedOptions[questions[index]._id]?.length > 0) {
        updateQuestionStatus(index, 'answeredMarkedReview');
    } else {
        updateQuestionStatus(index, 'markedForReview');
    }
    };
    
    const handleClearResponse = (index) => {
        // Clear the selected option for the current question
        const questionId = questions[index]._id;
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [questionId]: [] // Set it to an empty array instead of null
        }));
        updateQuestionStatus(index, 'notAnswered');
    };
    
    // const handleChangeQuestionButtonClick = (questionIndex) => {
    //     // Set the current question index to the clicked question
    //     setCurrentQuestionIndexForChange(questionIndex);
    //     console.log('Clicked on question number:', questionIndex + 1);
    // };
    
    // const handleChangeQuestionButtonClick = (questionIndex) => {
    //     // Set both current question indexes to the clicked question
    //    //setCurrentQuestionIndex(questionIndex);
    //     setCurrentQuestionIndexForChange(questionIndex);
    //     console.log('Clicked on question number:', questionIndex + 1);
    // };
    
    
    const handleChangeQuestionButtonClick = (questionIndex) => {
        setCurrentQuestionIndex(questionIndex);
        // Reset the flag indicating Save & Next button click
        setSaveNextClicked(false);
    };
    
    const handleSubmit = () => {
        // Save responses for all questions
        questions.forEach((question, index) => {
            // Logic to save responses for each question
            console.log(`Saved response for question ${index + 1}:`, selectedOptions[question._id]);
            if (selectedOptions[question._id]?.length > 0) {
                updateQuestionStatus(index, 'answered'); // Update question status to 'answered' if an option is selected
            } else {
                updateQuestionStatus(index, 'notAnswered'); // Update question status to 'notAnswered' if no option is selected
            }
            // You can add more conditions here to handle other cases
        });
    
        // Submit the exam (You can perform any additional actions needed for submitting the exam)
        console.log('Exam submitted');
    };
   

    // const handleNumberedButtonClick = (event, questionIndex) => {
    //     setCurrentQuestionIndex(questionIndex);
    //     // Set the flag to indicate that a numbered question button was clicked
    //     setNumberedButtonClicked(true);
    //     // Prevent event propagation to avoid triggering other button actions
    //     event.stopPropagation();
    // };
    
    const handleSaveAndNextButtonClick = () => {
        // Logic to save the response for the current question
        console.log('Saved response for question:', currentQuestionIndex);
        
        // Move to the next question if not on the last question
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        }
        
        // Reset the states for Save & Next buttons
        setClickedNumberedButton(false);
        setSaveNextClicked(true); // Indicate that "Save & Next" button was clicked
    };
    
   
    const handlePreviousButtonClick = () => {
        // Move to the previous question if not on the first question
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        }
    };
    
// Update the status of a question based on its index
const updateQuestionStatus = (index, status) => {
    const newQuestionStatus = [...questionStatus];
    newQuestionStatus[index] = status;
    setQuestionStatus(newQuestionStatus);

    
  };
    return (

<div>
<div className="bg-black text-2xl font-bold text-black w-full text-center">Sectional Details</div>
  

<div className="flex justify-between">

  {/* Left side content */}
  <div className="w-3/4 p-4">
    {/* Black header */}
    <div className="mt-4 flex justify-between">
                <div className="text-left">Section</div>
                {/* <div className="text-right">Time left: {totalDuration}</div> */}
                <div className="text-right">Time left: {formattedTime}</div>

            </div>

    {/* Body content */}
    <div className='mt-4'>
      {/* Render buttons for each section */}
      {sectionDetails.map((section, index) => (
        <button
          key={section._id}
          className={`rounded-md px-4 py-2 mr-2 ${section.test_section_id._id === selectedPaper ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          onClick={() => handleSectionClick(section.test_section_id._id)}
        >
          {section.test_section_id.name}
        </button>
      ))}
    </div>
    <div className="mt-4 flex justify-between">
  <div className="text-left text-red-500">Question Type Multiple Choice Question</div>
</div>

   
    <div className="mt-4 text-right">
        {selectedLanguage && (
            <p className="bg-blue-500 text-white px-4 py-2 rounded-md">view in:{` ${selectedLanguage}`}</p>
        )}
    </div>

    {/* Display questions */}
    <ul>
        {questions && questions.length > 0 ? (
            questions.map((question, index) => (
                <li key={question._id} className={`mb-8 ${currentQuestionIndex === index ? 'block' : 'hidden'}`}>
             {/* <li key={question._id} className={`mb-8 ${currentQuestionIndex === index || currentQuestionIndexForChange === index ? 'block' : 'hidden'}`}>  */}
                    <div className="text-lg font-bold bg-white text-black p-2 mb-4">
                        Question No. {question.question_no}
                    </div>
                    <div className="mb-4">{question.question_id.question}</div>
                    <ul>
                        {Object.entries(question.question_id)
                            .filter(([key, value]) => key.startsWith('option_') && value)
                            .map(([key, value]) => (
                                <li key={key} className="mb-2">
                                    <input
                                        type="checkbox"
                                        id={`option_${key}`}
                                        name={`question_${index}_${question._id}`}
                                        value={value}
                                        checked={selectedOptions[question._id]?.includes(key)}
                                        onChange={() => handleOptionChange(question._id, key)}
                                    />
                                    <label htmlFor={`option_${key}`} className="ml-2">{value}</label>
                                </li>
                            ))}
                    </ul>
                </li>
            ))
        ) : (
            <li>No questions found</li>
        )}
    </ul>

    {/* Action buttons */}
    <div className="fixed bottom-6 left-0 right-0 bg-white p-4 flex justify-between">
      <div>

        {/* Mark for Review and Clear Response buttons */}
        <button className="bg-white hover:shadow-md text-gray-700 px-4 py-2 rounded-md border border-gray-300 mr-2" onClick={() => handleMarkForReview(currentQuestionIndex)}>Mark for Review</button>
        <button className="bg-white hover:shadow-md text-gray-700 px-4 py-2 rounded-md border border-gray-300 mr-2" onClick={() => handleClearResponse(currentQuestionIndex)}>Clear Response</button>

        {currentQuestionIndex > 0 && (
            <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-5" onClick={() => handlePreviousButtonClick()}>Previous</button>
        )}
        <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md mr-2" onClick={() => handleSaveAndNextButtonClick()}>Save & Next</button>
        </div>
        </div>
    </div>

  {/* Right side content */}
  
  
  <div className="w-1/4 p-15">
  <div className="flex items-center mb-2 border border-gray-300 p-15">
    {/* Sidebar content for "John" section and numbered question buttons */}
    <div className="flex flex-col p-10">
      {/* Adjusted padding and margin here */}
      <div className="flex items-center mb-2 border border-gray-500 p-10 -mx-15">
        {/* Adjusted margin here */}
        <img src={user} alt="userimage" className="w-24 h-auto mr-5" />
        <p className="text-black">Jhon</p>
      </div>



      
      <div className="flex flex-wrap justify-center items-center border border-gray-300 p-2">
    <div className="flex flex-col items-center mb-4 mr-8">
        <div className="flex items-center mb-2">
            <img src={answered} alt="answered" className="w-5 h-auto mr-2" />
            <p className="text-left">Answered</p>
        </div>
        <div className="flex items-center mb-2">
            <img src={notAnswered} alt="notAnswered" className="w-5 h-auto mr-2" />
            <p className="text-left">Not Answered</p>
        </div>
    </div>
    <div className="flex flex-col items-center mb-4 mr-8">
        <div className="flex items-center mb-2">
            <img src={notvisited} alt="notvisited" className="w-5 h-auto mr-2" />
            <p className="text-left">Not Visited</p>
        </div>
        <div className="flex items-center mb-2">
            <img src={markforReview} alt="markforReview" className="w-5 h-auto mr-2" />
            <p className="text-left">Marked for Review</p>
        </div>
    </div>
    <div className="flex flex-col items-center mb-4">
        <div className="flex items-center mb-2">
            <img src={answeredmarkforReview} alt="answeredMarkedReview" className="w-5 h-auto mr-2" />
            <p className="text-left">Answered & Marked for Review (will be considered for evaluation)</p>
        </div>
    </div>
</div>


<div className="flex items-center mb-2 border border-gray-500  mt-2">
 {/* Render the section buttons */}
{sectionDetails.map((section, index) => (
  <button
    key={section._id}
    className={` px-4 py-2 mr-2 ${section.test_section_id._id === selectedPaper ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
    onClick={() => handleSectionClick(section.test_section_id._id)}
  >
    {section.test_section_id.name}
  </button>
))}
</div>
   

    {/* Render the selected paper name */}




      {/* Render the numbered question buttons */}
        {/* <div className="flex flex-col items-start border border-gray-300 ">
        <p className="text-black pl-4 mb-2">Choose a Question</p>
        <div className="flex flex-wrap justify-center">
        {selectedPaper && questions.map((_, i) => (
        <div key={i} className="mr-2 mb-2">
            <button className="bg-gray-300 hover:bg-gray-40 relative" onClick={(event) => handleNumberedButtonClick(event, i)}>
            <div className="relative">
                <img src={notvisited} alt="not visited" className="w-7 h-auto" />
                <span className="absolute top-0 left-0 px-2 py-1">{i + 1}</span>
            </div>
            </button>
        </div>
        ))}

        </div>

        </div> */}


        
 <div className="flex flex-col items-start border border-gray-300 ">
        <p className="text-black pl-4 mb-2">Choose a Question</p>
<div className="flex flex-wrap justify-center">
  {selectedPaper && questions.map((_, i) => (
    <div key={i} className="mr-2 mb-2">
      {(questionStatus[i] === 'answeredMarkedReview' || questionStatus[i] === 'markedForReview') && (
        <button className="bg-gray-300 hover:bg-gray-40 relative">
          {questionStatus[i] === 'answeredMarkedReview' && <img src={answeredmarkforReview} alt="answered & marked for review" className="w-7 h-auto" />}
          {questionStatus[i] === 'markedForReview' && <img src={markforReview} alt="marked for review" className="w-7 h-auto" />}
          <span className="absolute top-0 left-0 px-2 py-1">{i + 1}</span>
        </button>
      )}
      {(questionStatus[i] !== 'answeredMarkedReview' && questionStatus[i] !== 'markedForReview') && (
        <button className="bg-gray-300 hover:bg-gray-40 relative" onClick={() => handleChangeQuestionButtonClick(i)}>
          {questionStatus[i] === 'answered' && <img src={answered} alt="answered" className="w-7 h-auto" />}
          {questionStatus[i] === 'notAnswered' && <img src={notAnswered} alt="not answered" className="w-7 h-auto" />}
          {questionStatus[i] === 'notVisited' && <img src={notvisited} alt="not visited" className="w-7 h-auto" />}
          <span className="absolute top-0 left-0 px-2 py-1">{i + 1}</span>
        </button>
      )}
    </div>
  ))}
</div>
</div>



<button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md mr-2 mt-2"  onClick={() => handleSubmit()}>Submit</button>
    </div>
  </div>
</div>

</div>

<div className="fixed bottom-0 left-0 w-full bg-blue-400 text-white text-center py-2">
  <div className="text-2xl font-bold"></div>
</div>


</div>
  






    );
};

export default SectionalDetails;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';

// const SectionalDetails = () => {
//     const location = useLocation(); 
//     const selectedLanguage = location.state ? location.state.selectedLanguage : null;
//     const [selectedPaper, setSelectedPaper] = useState('');
//     const [sectionDetails, setSectionDetails] = useState([]);
//     const [questions, setQuestions] = useState([]);
//     const { testId } = useParams();
    
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [selectedOptions, setSelectedOptions] = useState({});

//     useEffect(() => {
//         setSelectedPaper(location.state.selectedPaper);
//         fetchSectionDetails();
//     }, [testId, location.state.selectedPaper]);

//     const fetchSectionDetails = async () => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
            
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             const selectedPaperId = location.state.selectedPaper;
//             const filteredSectionDetails = response.data.test_section_details.filter(section => {
//                 return section.test_section_id._id === selectedPaperId || !section.is_optional;
//             });

//             if (filteredSectionDetails.length > 0) {
//                 const firstSectionId = filteredSectionDetails[0].test_section_id._id;
//                 fetchQuestionsByTest(testId, firstSectionId);
//             }

//             setSectionDetails(filteredSectionDetails);
//         } catch (error) {
//             console.error('Error fetching section details:', error.message);
//         }
//     };

//     const fetchQuestionsByTest = async (testId, sectionId) => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_question_by_test?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });
    
//             const filteredQuestions = response.data.questions.filter(question => {
//                 return question.test_id === testId && question.test_section_detail_id.test_section_id._id === sectionId;
//             });
    
//             // Initialize selected options for each question with empty arrays
//             const initialSelectedOptions = {};
//             filteredQuestions.forEach(question => {
//                 initialSelectedOptions[question._id] = [];
//             });
    
//             setSelectedOptions(initialSelectedOptions);
//             setQuestions(filteredQuestions);
//         } catch (error) {
//             console.error('Error fetching questions:', error);
//         }
//     };
    

//     const handleSectionClick = (sectionId) => {
//         setSelectedPaper(sectionId);
//         if (sectionDetails.find(section => section.test_section_id._id === sectionId)?.is_optional) {
//             setCurrentQuestionIndex(0); // Set current question index to 0 if the selected section is optional
//         }
//         fetchQuestionsByTest(testId, sectionId);
//     };
    
//     // const handleOptionChange = (questionId, optionKey) => {
//     //     setSelectedOptions(prevOptions => ({
//     //         ...prevOptions,
//     //         [questionId]: optionKey
//     //     }));
//     // };

//     const handleOptionChange = (questionId, optionKey) => {
//         setSelectedOptions(prevOptions => {
//             const newOptions = { ...prevOptions };
//             // Toggle the selection of the option
//             if (newOptions[questionId]?.includes(optionKey)) {
//                 newOptions[questionId] = newOptions[questionId].filter(selectedKey => selectedKey !== optionKey);
//             } else {
//                 newOptions[questionId] = [...(newOptions[questionId] || []), optionKey];
//             }
//             return newOptions;
//         });
//     };
    
    
    
//     const handleMarkForReview = (index) => {
//         // Logic to mark the question for review
//         console.log('Marked for review:', index);
//     };
    
//     const handleClearResponse = (index) => {
//         // Clear the selected option for the current question
//         const questionId = questions[index]._id;
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [questionId]: null
//         }));
//     };
    
//     const handleSaveResponse = () => {
//         // Logic to save the response for the question
//         console.log('Saved response for question:', currentQuestionIndex);
//         if (currentQuestionIndex < questions.length - 1) {
//             setCurrentQuestionIndex(prevIndex => prevIndex + 1);
//         }
//     };

//     const handlePreviousQuestion = () => {
//         // Logic to move to the previous question
//         setCurrentQuestionIndex(prevIndex => prevIndex - 1);
//     };

//     return (
//         <div className="flex justify-start ">
//             <div className="w-1/2">
//                 <h1 className="text-2xl font-bold mb-6">Sectional Details</h1>
//                 <div>
//                     {/* Render buttons for each section */}
//                     {sectionDetails.map((section, index) => (
//                         <button
//                             key={section._id}
//                             className={`rounded-md px-4 py-2 mr-2 ${section.test_section_id._id === selectedPaper ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
//                             onClick={() => handleSectionClick(section.test_section_id._id)}
//                         >
//                             {section.test_section_id.name}
//                         </button>
//                     ))}
//                 </div>

//                <div>

//                {selectedLanguage && (
//                 <p>Selected Language: {selectedLanguage}</p>
//             )}
//                </div>
//                 <div>
//                     {/* Display questions */}
//                     <h2 className="text-xl font-bold mt-6 mb-4">Questions</h2>
//                     <ul>
//                     {questions && questions.length > 0 ? (
//     questions.map((question, index) => (
//         <li key={question._id} style={{ display: currentQuestionIndex === index ? 'block' : 'none' }}>
//             <div>
//                 Question {question.question_no}: {question.question_id.question}
//             </div>
//             <ul>
//             {Object.entries(question.question_id)
//     .filter(([key, value]) => key.startsWith('option_') && value)
//     .map(([key, value]) => (
//         <li key={key}>
//             <input
//                 type="checkbox"
//                 id={`option_${key}`}
//                 name={`question_${index}_${question._id}`}
//                 value={value}
//                 checked={selectedOptions[question._id]?.includes(key)}
//                 onChange={() => handleOptionChange(question._id, key)}
//             />
//             <label htmlFor={`option_${key}`}>{value}</label>
//         </li>
//     ))}

//             </ul>
//             <div className="mt-4">
//                 <button className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md" onClick={() => handleMarkForReview(index)}>Mark for Review</button>
//                 <button className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md" onClick={() => handleClearResponse(index)}>Clear Response</button>
//                 {currentQuestionIndex > 0 && (
//                     <button className="mr-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md" onClick={handlePreviousQuestion}>Previous</button>
//                 )}
//                 {currentQuestionIndex === questions.length - 1 && (
//                     <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md" onClick={handleSaveResponse}>Save</button>
//                 )}
//                 {currentQuestionIndex < questions.length - 1 && (
//                     <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md" onClick={handleSaveResponse}>Save & Next</button>
//                 )}
//             </div>
//         </li>
//     ))
// ) : (
//     <li>No questions found</li>
// )}

//                     </ul>

//                     <div className="mt-4">
//                         {questions.map((_, index) => (
//                             <button key={index} className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md" onClick={() => setCurrentQuestionIndex(index)}>
//                                 {index + 1}
//                             </button>
//                         ))}
//                     </div>
//                 </div>

//             </div>

            
//         </div>
//     );
// };

// export default SectionalDetails;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation, useParams } from 'react-router-dom';

// const SectionalDetails = () => {
//     const [selectedPaper, setSelectedPaper] = useState('');
//     const [sectionDetails, setSectionDetails] = useState([]);
//     const [questions, setQuestions] = useState([]);
//     const { testId } = useParams();
//     const location = useLocation();
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [selectedOptions, setSelectedOptions] = useState({});

//     useEffect(() => {
//         setSelectedPaper(location.state.selectedPaper);
//         fetchSectionDetails();
//     }, [testId, location.state.selectedPaper]);

//     const fetchSectionDetails = async () => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
            
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_test_section_details?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             const selectedPaperId = location.state.selectedPaper;
//             const filteredSectionDetails = response.data.test_section_details.filter(section => {
//                 return section.test_section_id._id === selectedPaperId || !section.is_optional;
//             });

//             if (filteredSectionDetails.length > 0) {
//                 const firstSectionId = filteredSectionDetails[0].test_section_id._id;
//                 fetchQuestionsByTest(testId, firstSectionId);
//             }

//             setSectionDetails(filteredSectionDetails);
//         } catch (error) {
//             console.error('Error fetching section details:', error.message);
//         }
//     };

//     const fetchQuestionsByTest = async (testId, sectionId) => {
//         try {
//             const authToken = localStorage.getItem('token');
//             if (!authToken) {
//                 console.error('Authentication token not found');
//                 return;
//             }
//             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_question_by_test?test_id=${testId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${authToken}`
//                 }
//             });

//             const filteredQuestions = response.data.questions.filter(question => {
//                 return question.test_id === testId && question.test_section_detail_id.test_section_id._id === sectionId;
//             });

//             // Initialize selected options for each question
//             const initialSelectedOptions = {};
//             filteredQuestions.forEach(question => {
//                 initialSelectedOptions[question._id] = null;
//             });

//             setSelectedOptions(initialSelectedOptions);
//             setQuestions(filteredQuestions);
//         } catch (error) {
//             console.error('Error fetching questions:', error);
//         }
//     };

//     const handleSectionClick = (sectionId) => {
//         setSelectedPaper(sectionId);
//         if (sectionDetails.find(section => section.test_section_id._id === sectionId)?.is_optional) {
//             setCurrentQuestionIndex(0); // Set current question index to 0 if the selected section is optional
//         }
//         fetchQuestionsByTest(testId, sectionId);
//     };
    
//     const handleOptionChange = (questionId, optionKey) => {
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [questionId]: optionKey
//         }));
//     };


    
//     const handleMarkForReview = (index) => {
//         // Logic to mark the question for review
//         console.log('Marked for review:', index);
//     };
    
//     const handleClearResponse = (index) => {
//         // Clear the selected option for the current question
//         const questionId = questions[index]._id;
//         setSelectedOptions(prevOptions => ({
//             ...prevOptions,
//             [questionId]: null
//         }));
//     };
    
//     const handleSaveResponse = () => {
//         // Logic to save the response for the question
//         console.log('Saved response for question:', currentQuestionIndex);
//         if (currentQuestionIndex < questions.length - 1) {
//             setCurrentQuestionIndex(prevIndex => prevIndex + 1);
//         }
//     };

//     const handlePreviousQuestion = () => {
//         // Logic to move to the previous question
//         setCurrentQuestionIndex(prevIndex => prevIndex - 1);
//     };

//     return (
//         <div className="flex justify-start ">
//             <div className="w-1/2">
//                 <h1 className="text-2xl font-bold mb-6">Sectional Details</h1>
//                 <div>
//                     {/* Render buttons for each section */}
//                     {sectionDetails.map((section, index) => (
//                         <button
//                             key={section._id}
//                             className={`rounded-md px-4 py-2 mr-2 ${section.test_section_id._id === selectedPaper ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
//                             onClick={() => handleSectionClick(section.test_section_id._id)}
//                         >
//                             {section.test_section_id.name}
//                         </button>
//                     ))}
//                 </div>
//                 <div>
//                     {/* Display questions */}
//                     <h2 className="text-xl font-bold mt-6 mb-4">Questions</h2>
//                     <ul>
//                         {questions && questions.length > 0 ? (
//                             questions.map((question, index) => (
//                                 <li key={question._id} style={{ display: currentQuestionIndex === index ? 'block' : 'none' }}>
//                                     <div>
//                                         Question {question.question_no}: {question.question_id.question}
//                                     </div>
//                                     <ul>
//                                         {Object.entries(question.question_id)
//                                             .filter(([key, value]) => key.startsWith('option_') && value)
//                                             .map(([key, value]) => (
//                                                 <li key={key}>
//                                                     <input
//                                                         type="radio"
//                                                         name={`question_${index}`}
//                                                         value={value}
//                                                         checked={selectedOptions[question._id] === key}
//                                                         onChange={() => handleOptionChange(question._id, key)}
//                                                         style={{ 
//                                                             appearance: 'none', 
//                                                             width: '16px', 
//                                                             height: '16px', 
//                                                             borderRadius: '50%', 
//                                                             border: '2px solid #ccc', 
//                                                             backgroundColor: selectedOptions[question._id] === key ? '#ccc' : 'transparent', 
//                                                             outline: 'none', 
//                                                             marginRight: '8px',
//                                                             verticalAlign: 'middle' // To align the circle vertically with the text
//                                                         }}
//                                                     />
//                                                     {value}
//                                                 </li>
//                                             ))}
//                                     </ul>
//                                     <div className="mt-4">
//                                         <button className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md" onClick={() => handleMarkForReview(index)}>Mark for Review</button>
//                                         <button className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md" onClick={() => handleClearResponse(index)}>Clear Response</button>
//                                         {currentQuestionIndex > 0 && (
//                                             <button className="mr-2 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md" onClick={handlePreviousQuestion}>Previous</button>
//                                         )}
//                                         {currentQuestionIndex === questions.length - 1 && (
//                                             <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md" onClick={handleSaveResponse}>Save</button>
//                                         )}
//                                         {currentQuestionIndex < questions.length - 1 && (
//                                             <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md" onClick={handleSaveResponse}>Save & Next</button>
//                                         )}
//                                     </div>
//                                 </li>
//                             ))
//                         ) : (
//                             <li>No questions found</li>
//                         )}
//                     </ul>
//                 </div>

//             </div>

            
//         </div>
//     );
// };

// export default SectionalDetails;



