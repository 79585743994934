import React, { useState, useEffect } from 'react';
import axios from 'axios';
import img11 from "../../images/11.png"
import { NavLink ,useParams} from 'react-router-dom';
import img22 from "../../images/22.png"
import imgcopy22 from "../../images/22Copy.png"

const RecommendedTest = ({ subCategoryId }) => {
    const [testSeries, setTestSeries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
  
    
   
    useEffect(() => {
        const fetchTestSeries = async () => {
            try {
                // const authToken = localStorage.getItem('token');
                const authToken = "";
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/test_series?sub_category_id=${subCategoryId}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                console.log(response, "response");
                
                setTestSeries(response.data.test_series);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchTestSeries();
    }, [subCategoryId]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    

    return (
        <>
    
       

    <section className="news">
        <div className="container">
          <div className="row justify-content-center">
            <div className='col-md-1 col-2'>
              <img src={img11} style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
            <h3 className='uu'><span>Recommended</span>Test Series </h3>
            </div>





            <div className='col-md-1 col-1 mb-2'>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulhefwfs" data-slide="prev">
                    <img src={imgcopy22} style={{ width: "300%" }} alt='sarkariexam' />
                </button>
                
            </div>
            <div className='col-md-10 col-10 mb-2'>
            <div id="carouselExampleControlsfyfklulhefwfs" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {testSeries.reduce((chunks, series, index) => {
                        const chunkIndex = Math.floor(index / 4);
                        if (!chunks[chunkIndex]) {
                            chunks[chunkIndex] = [];
                        }
                        chunks[chunkIndex].push(series);
                        return chunks;
                    }, []).map((chunk, chunkIndex) => (
                        <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                            <div className="row">
                                {chunk.map(series => (
                                    <div key={series._id} className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                        <div className="abt">
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${series.image}`} className='img-fluid' style={{ width: "100%" }} alt={series.title} />
                                            <h5>{series.title}</h5>
                                            <p className='pt-0 pb-0 mb-2' style={{ color: "rgb(24 79 168)", fontWeight: 600 }}>Total Test : 300 | 5 Test Free</p>
                                            <p className='pt-1 pb-0 mb-0'>Learn in Language</p>
                                            <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>Hindi & English</strong></p>
                                            <p className='pt-0 pb-0 mb-2'>80 Chapter Test</p>
                                            <hr style={{ border: "1px solid grey", margin: "0px" }} />
                                            <p className='pt-0 pb-0 mb-2'>136 Chapter Tests (Paper ll)</p>
                                            <hr style={{ border: "1px solid grey", margin: "0px" }} />
                                            <p className='pt-0 pb-0 mb-2'>11 Full Tests (Paper l)</p>
                                            <hr style={{ border: "1px solid grey", margin: "0px" }} />
                                            <p className='pt-0 pb-0 mb-2'><strong style={{ color: "#535ed6" }}>+73 extra Tests</strong></p>
                                            <p className='pt-0 pb-0 mb-2'><strong>Series Type:</strong> {series.series_type}</p>
                                        </div>
                                        <NavLink to={`/testseries_details/${series._id}`}>
                                            <p className='oo'>Test Series</p>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>
            </div>
            <div className='col-md-1 col-1 mb-2'>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulhefwfs" data-slide="next">
                    <img src={img22} style={{ width: "300%" }} alt='sarkariexam' />
                </button>
            </div>


















            
         
          </div>
        </div>
      </section>


    
        </>
    );
};

export default RecommendedTest;

