import { useEffect } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

export default function About() {
  useEffect(() => {
    function counter(id, start, end, duration) {
      let obj = document.getElementById(id);
      if (!obj) return;
      let current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
          current += increment;
          obj.textContent = current;
          if (current === end) {
            clearInterval(timer);
          }
        }, step);
    }
    counter("count1", 0, 4, 1000);
    counter("count2", 0, 1000, 1000);
    counter("count3", 0, 10, 1000);
    counter("count4", 0, 5, 1000);
  }, []);

  return (
    <>
      <Header />
      <img className='srimg' src="abt.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section className="cc">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 style={{ color: "#2e1b1d" }}>About Pensbook</h3>
              <hr style={{ border: "1px solid #f27e18" }} />
              <p style={{ textAlign: "justify" }}>
                The All India Quiz Championships is a unique program to empower
                school students with knowledge and build strong foundations for the
                future challenges. Connecting each student in a national competition
                from early ages. Pensbook puts a student into a continuous learning
                mode with opportunity to test it at regular intervals against
                competitors from across the country. So join in and compete with the
                rest to be the BEST!
              </p>
              <p style={{ textAlign: "justify" }}>
                The Pensbook All India Quiz Championships, endeavours to provide a
                platform to promote continuous learning, development of knowledge,
                bringing out the best in each child.
              </p>
            </div>
            <div className="col-md-5">
              <img src="abttest.jpg" width="100%" style={{ borderRadius: 20 }} />
            </div>
          </div>
        </div>
      </section>
      <section className="raf" style={{
        background: "url(dfddfvbds.png)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h3 style={{ color: "#2e1b1d" }}>Pensbook Benefits</h3>
              <hr style={{ border: "1px solid #f27e18" }} />
              <p style={{ textAlign: "justify" }}>
                The Pensbook Graded Learning Program will assist students to acquire
                knowledge in a systematic way. We at Pensbook believe that learning,
                participation, recognition and achievement matter for school
                students especially in their formative years.
              </p>
              <ul>
                <li>
                  Progressive Knowledge Development for each individual learner at
                  school
                </li>
                <li>
                  Facilitating Schools with support to foster knowledge development
                  of students
                </li>
                <li>
                  Providing an All India Platform for continuous participation
                </li>
                <li>Regular and Periodic Opportunity to win prizes</li>
                <li>
                  Provide curricular and non-curricular learning &amp; assessment
                </li>
                <li>
                  Provide Graded Learning Content and Assessment in Quiz format
                </li>
                <li>Creating a knowledge oriented society</li>
              </ul>
            </div>
            <div className="col-md-4">
              <center>
                <img src="01.png" width="100%" />
              </center>
            </div>
          </div>
        </div>
      </section>
      <section className="con" id="counter" style={{
        background: "url(dfddfvbds.png)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }}>
        <div className="container">
          <h3 style={{ color: "#2e1b1d" }}>Facts &amp; Figure</h3>
          <hr style={{ border: "1px solid #f27e18" }} />
          <div className="row">
            <div className="col-md-3 col-12">
              <center>
                <h3 style={{ color: "#16402d", fontSize: 60 }}>
                  <div className="NumCounter count">
                    <span className="counter-value" id="count1" data-count={107}>
                      0
                    </span>
                     k
                  </div>
                </h3>
                <h4 style={{ color: "#ef4136" }}>Students Enrolled</h4>
              </center>
            </div>
            <div className="col-md-3 col-12">
              <center>
                <h3 style={{ color: "#16402d", fontSize: 60 }}>
                  <div className="NumCounter">
                    <span className="counter-value" id="count2" data-count={10000}>
                      0
                    </span>
                    {/* + */}
                  </div>
                </h3>
                <h4 style={{ color: "#ef4136" }}>Test Series</h4>
              </center>
            </div>
            <div className="col-md-3 col-12">
              <center>
                <h3 style={{ color: "#16402d", fontSize: 60 }}>
                  <div className="NumCounter">
                    <span className="counter-value" id="count3" data-count={21}>
                      0
                    </span>
                    k
                  </div>
                </h3>
                <h4 style={{ color: "#ef4136" }}>Exam</h4>
              </center>
            </div>
            <div className="col-md-3 col-12">
              <center>
                <h3 style={{ color: "#16402d", fontSize: 60 }}>
                  <div className="NumCounter">
                    <span className="counter-value" id="count4" data-count={21}>
                      0
                    </span>
                    k
                  </div>
                </h3>
                <h4 style={{ color: "#ef4136" }}>Add Result</h4>
              </center>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
