import Header from '../components/header';
import Footer from '../components/footer';
import App from '../App.css';
import React,{useState,useEffect} from 'react';
import axios from 'axios';
import band from "../images/band1.jpg"
import band1 from "../images/band2.jpg"
import band2 from "../images/band3.jpg"
import img31 from "../images/31.png"
import img32 from "../images/32.png"
import img30 from "../images/30.png"
import img29 from "../images/29.png"

import img04 from "../images/04.png"
import img05 from "../images/05.png"
import img06 from "../images/06.png"
import img07 from "../images/07.png"
import img08 from "../images/08.png"
import img22 from "../images/22Copy.png"

import img23 from "../images/23.png"
import img24 from "../images/24.png"
import img25 from "../images/25.png"
import img26 from "../images/26.png"

import img20 from "../images/20.jpg"
import img19 from "../images/19.png"
import img16 from "../images/16.png"
import img18 from "../images/18.png"
import img17 from "../images/17.png"

import banm from "../images/banm1.jpg"
import banm2 from "../images/banm2.jpg"
import banm3 from "../images/banm3.jpg"

import Category from '../components/category/category';
import RecommendedTest from '../components/category/RecommendedTest';
import DailyDose from '../components/category/DailyDose';
import ScheduledWise from '../components/category/sheduledWise';
import PopularTestSeries from '../components/category/popularTestSeries';

export default function Home() {

  const [testSeries, setTestSeries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected_sub_category, setSelectedSubCategory] = useState('');


  
    useEffect(() => {
        const fetchTestSeries = async () => {
            try {
                const authToken = "";
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/test_series?sub_category_id=${selected_sub_category}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
               
                
                setTestSeries(response.data.test_series);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchTestSeries();
    }, [selected_sub_category]);

    // if (isLoading) {
    //     return <p>Loading...</p>;
    // }

    // if (error) {
    //     return <p>Error: {error}</p>;
    // }

  
  return (
    <>
      <Header />
      <section className='desktop'>
        <section className='carro'>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={band} className="d-block w-100" alt="band1.jpg" />
              </div>
              <div className="carousel-item">
                <img src={band1} className="d-block w-100" alt="band2.jpg" />
              </div>
              <div className="carousel-item">
                <img src={band2} className="d-block w-100" alt="band3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section>

      <section className='mobile'>
        <section className='carro'>
          <div id="carouselExampleControlssaiiias" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={banm} className="d-block w-100" alt="banm1.jpg" />
              </div>
              <div className="carousel-item">
                <img src={banm2} className="d-block w-100" alt="banm2.jpg" />
              </div>
              <div className="carousel-item">
                <img src={banm3} className="d-block w-100" alt="banm3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlssaiiias" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControlssaiiias" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section>





      <section className='welcome'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-12 text-center'>
              <p className='we'>Welcome To</p>
              <p className='indi'>India’s No. 1</p>
              <p className='in'>Pensbook Portal </p>
              <p className='for'>For Completing Your Exam’s Preparation</p>
              <p className='dow'>Download Our App From</p>
            </div>
            <div className='col-md-2 col-5'>
              <center><img src={img31} className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
            </div>
            <div className='col-md-2 col-5'>
              <center><img src={img32} className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
            </div>
          </div>
          <div className='row justify-content-center pt-4'>
            <div className='col-md-3 col-8'>
              <center><img src={img04} className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src={img05} className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src={img06} className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
            <div className='col-md-3 col-8'>
              <center><img src={img07} className="App-logo pt-2 pb-2" alt="logo" style={{ width: "90%" }} /></center>
            </div>
          </div>
        </div>
      </section>

      
      <Category />

      {/* <DailyDose/> */}

      <section className='iimm'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <img src={img20} style={{ width: "100%" }} alt='sarkariexam' />
            </div>
            <div className='col-md-6'>
              <h3 style={{ color: "#e40612" }}>Register Now on Pensbook Exams and Enjoy 1000+ Exams</h3>
              <h6 className='pt-2 pb-2'>Get unlimited access to the most relevant Mock Tests | Test Series | Mock Discussion etc with our Pensbook Exams</h6>
              <div className='row justify-content-start'>
                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src={img19} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>All India Rank</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src={img16} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>Latest Test Series</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src={img18} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>Latest Exam Patterns</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-6 pt-3 pb-2'>
                  <div className='zoom'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-4 col-6'>
                        <img src={img17} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-8 col-12' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-2'>Mock Test Discussion</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <a href="https://pensbook.com/login"><p className='btn btn-lg btn-block' style={{ borderRadius: "20px", background: "#e40612", color: "#fff", fontSize: "25px" }}>Register Now</p></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <RecommendedTest/>
      <ScheduledWise />
      {<PopularTestSeries type="popular" />}
      {/* borderRadius: "68px", background: "linearGradient(45deg, #6a6de2, #8ad1c6)", color: "rgb(255 255 255)", fontSize:"25px", fontWeight: "600" */}











































      <section className='aza'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h3>Reason of Choosing</h3>
              <h4>Pensbook Exams</h4>
              <hr style={{ border: "5px solid orange", width: "20%", float: "left", margin: "0px 0px 5px 0px" }} /><br></br>
              <p style={{ textAlign: "justify" }}>Your Gateway to Government Jobs. Stay updated with the latest notifications, admit cards, exam dates, and resultss for various government exams across India. Access valuable information on recruitment processes for positions in banking, railways, defense, education, and more. Pensbook Exam offers comprehensive insights into exam patterns, previous papers, and results announcements, aiding aspirants in their pursuit of securing prestigious government positions.</p>
            </div>
            <div className='col-md-8'>
              <div className='row justify-content-start'>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh1'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src={img23} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>All India Rank</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Learn from the best Test Series in the most engaging yet simplified ways.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh2'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src={img24} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>Latest Test Series</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Fee the thrill of a real exam improve your time & management skills.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh2'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                        <img className='pb-2' src={img25} style={{ width: "100%" }} alt='sarkariexam' />
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>Latest Exam Patterns</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Choose the Language & Learn in the language you are most comfortable with. </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-12 pt-3 pb-2'>
                  <div className='bh1'>
                    <div className='row justify-content-start pl-3'>
                      <div className='col-md-3 col-3'>
                      {/* <img src={img18} style={{ width: "100%" }} alt='sarkariexam' /> */}
                      <img src={img17} style={{ width: "100%" }} alt='sarkariexam' />
                        {/* <img className='pb-2' src={img26} style={{ width: "100%" }} alt='sarkariexam' /> */}
                      </div>
                      <div className='col-md-9 col-9' style={{ paddingLeft: "0px" }}>
                        <h5 className='text-left pt-3'>Mock Test Discussion</h5>
                      </div>
                      <div className='col-md-12 col-12'>
                        <p>Mock Test Support for your best preparation. you will feel like you are studying in class.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>



      <section className='bggb' style={{ paddingLeft: "30px" }}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-4 col-12 pt-5 pb-4' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <p className='pp1'>Start Your Preparation Journey with Our</p>
              <p className='pp2'>Pensbook Exam App or Web Portal</p>
              <img className='' src={img29} style={{ width: "90%" }} alt='sarkariexam' />
              <p className='pp3'>Download our App from</p>
              <div className='row'>
                <div className='col-md-6 col-4'>
                  <center><img src={img31} className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
                </div>
                <div className='col-md-6 col-4'>
                  <center><img src={img32} className="App-logo" alt="logo" style={{ width: "100%" }} /></center>
                </div>
              </div>
            </div>
            <div className='col-md-8 col-12'>
              <img className='' src={img30} style={{ width: "100%" }} alt='sarkariexam' />
            </div>
          </div>
        </div>
      </section>



      <section className='vcvc'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-1 col-2'>
              <img src={img08} style={{ width: "100%" }} />
            </div>
            <div className=' uc col-md-11 col-10 mb-2'>
              <h3 className='uu'><span>Mock Test</span> Discussion</h3>
            </div>
            <div className='col-md-12'>
              <div id="carouselExampleControlsfyfklulh" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button class="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>
                  <div className="carousel-item">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", justifyContent: "center" }}>
                      <li className="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-ssc-tab" data-toggle="pill" data-target="#pills-ssc" type="button" role="tab" aria-controls="pills-ssc" aria-selected="true">SSC EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-railway-tab" data-toggle="pill" data-target="#pills-railway" type="button" role="tab" aria-controls="pills-railway" aria-selected="false">RAILWAY EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-defence-tab" data-toggle="pill" data-target="#pills-defence" type="button" role="tab" aria-controls="pills-defence" aria-selected="false">DEFENCE EXAM</button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-banking-tab" data-toggle="pill" data-target="#pills-banking" type="button" role="tab" aria-controls="pills-banking" aria-selected="false">BANKING EXAMS</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-states-tab" data-toggle="pill" data-target="#pills-states" type="button" role="tab" aria-controls="pills-states" aria-selected="false">STATES EXAMS</button>
                      </li>

                    </ul>
                  </div>

                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="prev">
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Previous</span> */}
                  <img src={img22} style={{ width: "25%" }} alt='sarkariexam' />
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleControlsfyfklulh" data-slide="next">
                  {/* <span className="carousel-control-next-icon" aria-hidden="true" /> */}
                  {/* <span className="sr-only">Next</span> */}
                  <img src='22.png' style={{ width: "25%" }} alt='sarkariexam' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className='lloo'>
          <div className='container'>
            <div className='row justify-content-center'>

              <div className='col-md-12'>

                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-ssc" role="tabpanel" aria-labelledby="pills-ssc-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gXhdno374Og?si=BzoVmxPYExzFGxR7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/-1einz1-8OU?si=h0Dt10N3JRgscYMa" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/fdDa2jeGx5g?si=lQIWEcRhIKKelEIY" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">SSC EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-railway" role="tabpanel" aria-labelledby="pills-railway-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/8fXtK8O8YeA?si=_bHmmzikY3C_GwB2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/5Gy7LAG7uV8?si=FYC_lP1NEn8r1yIM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/nBjdUJlRZUc?si=B3qqjiiZi1c28UzM" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">RAILWAY EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-defence" role="tabpanel" aria-labelledby="pills-defence-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/7y_tlPQFtk0?si=-gOwo4tWlXTVgX4Y" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CZyRrNgb6qU?si=yPDmN229QhOJDcW7" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/CvjcN6ErK34?si=bIAIrA00iZnoYhL2" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">DEFENCE EXAM</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-banking" role="tabpanel" aria-labelledby="pills-banking-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/BGN5IUSLk_c?si=4ObMw-AgAFv15hcR" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/gpA7BpubhdU?si=CTFTlQGbGnPvc8VW" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/EpTupTIKRCM?si=fbDh6oDaf3isBA1w" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">BANKING EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="tab-pane fade" id="pills-states" role="tabpanel" aria-labelledby="pills-states-tab">
                    <section>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/xQyE0Qzz6XE?si=DebtTLR-6PBN37FG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/zNPKQjAFsvc?si=9S4pTuEs85dzrLmz" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 pt-2 pb-2'>
                            <div className="card" style={{ borderRadius: "20px", padding: "10px" }}>
                              <iframe style={{ width: "100%", height: "200px", borderRadius: "20px" }} src="https://www.youtube.com/embed/KO3gtJh9XV8?si=2HmWP-2bINCFz8zm" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" />
                              <div className="card-body" style={{ paddingTop: "10px", paddingBottom: "0px" }}>
                                <div className="row">
                                  <div className='col-md-6 col-6'>
                                    <h6 className='mb-0'>Subject</h6>
                                    <h5 className="card-title popp">STATES EXAMS</h5>

                                  </div>
                                  <div className='col-md-6 col-6 text-right'>
                                    <h6 className='mb-0'>Duration : 1 hrs</h6>
                                    <h6>Full Chapter</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                </div>
                <div className='col-md-12'>
                  <p className='btn btn-lg btn-block mt-3 mb-2' style={{ borderRadius: "20px", background: "#fff", color: "#898989", fontSize: "25px", fontWeight: "600" }}>A Complete MockTest Discussion Video Support For Your Better Preparation</p>
                </div>
              </div>



            </div>
          </div>
        </section>
      </section>
      <Footer />


    </>
  )
}


