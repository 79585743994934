import Header from '../components/header';
import Footer from '../components/footer';

export default function Testseriesall() {
  return (
    <>
      <Header />
      <section className='t'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='row'>
                <div className='col-md-1 col-2'>
                  <img src='sbi.png' style={{ width: "100%" }} />
                </div>
                <div className='col-md-11 col-10'>
                  <h3 className="card-title">SBI PO (Pre + Mains) Mock Test 2023</h3>
                </div>
                <div className='col-md-12'>
                  <p>Last updated on Mar 23, 2024</p>
                </div>

                <div className='col-md-3'>
                  <p className='aaa' style={{ fontWeight: "600" }}>244 Total Tests </p>
                </div>
                <div className='col-md-3'>
                  <div className='aaaa'>
                    <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <p className='aaa' style={{ fontWeight: "600" }}>187.7k Users</p>
                </div>
                <div className='col-md-3'>
                  <p style={{ fontWeight: "600" }}>English,Hindi</p>
                </div>
                <div className='col-md-12'>
                  <hr style={{ border: "1px solid #000", margin: "0px" }} />
                </div>
                <div className='col-md-4'>
                  <ul>
                    <li>10 Full Test (Mains)</li>
                    <li>12 Sectional Test (Mains)</li>
                  </ul>
                </div>
                <div className='col-md-4'>
                  <ul>
                    <li>25 Full Test (Prelims)</li>
                    <li>15 Sectional Test (Prelims)</li>
                  </ul>
                </div>
                <div className='col-md-4'>
                  <ul>
                    <li>21 Memory Based Papers</li>
                    <li>115 Chapter Test</li>
                  </ul>
                </div>
              </div>
              <div className='btn btn-danger btn-block mb-2'>Add This Test Series  </div>
            </div>
            <div className='col-md-4'>
              <div className='fform'>
                <h5 className='text-center'>Sign up To Test Your Exam Knowledge Now!</h5>
                <form>
                  <input className='form-control mt-2 mb-2 text-center' type='text' placeholder='Enter Your Mobile Number' />
                  <p className='btn btn-block btn-success'>Signup & Take Free Tests</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xs">
        <div className="container">
          <h2>SBI PO (Pre + Mains) Mock Test 2023 Free Mock Tests</h2>
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ display: "flex", overflowX: "auto", overflowY: "hidden", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button className="nav-link active" data-toggle="pill" data-target="#pills-jssc" type="button" role="tab" aria-selected="false">
                    Full Test (Mains)(10)
                  </button>
                </li>
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button className="nav-link" data-toggle="pill" data-target="#pills-contact" type="button" role="tab" aria-selected="false">
                    Sectional Test (Mains)(12)
                  </button>
                </li>
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button className="nav-link" data-toggle="pill" data-target="#pills-profile" type="button" role="tab" aria-selected="false">
                    Full Test (Prelims)(25)
                  </button>
                </li>
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button className="nav-link" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-selected="true">
                    Chapter Test(115)
                  </button>
                </li>
                <li className="nav-item mb-3 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s" role="presentation" style={{ marginRight: 15 }}>
                  <button className="nav-link" data-toggle="pill" data-target="#pills-jslps" type="button" role="tab" aria-selected="false">
                    Sectional Test (Prelims)
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade" id="pills-home" role="tabpanel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 wow animated fadeInLeft" data-wow-duration="2s" data-wow-delay="1s">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Chapter Test Series - 2023
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12 wow animated fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Chapter Test Series - 2023
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Full Test (Prelims) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Full Test (Prelims) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Sectional Test (Mains) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Sectional Test (Mains) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade show active" id="pills-jssc" role="tabpanel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                 Full Test (Mains) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                 Full Test (Mains) - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-jslps" role="tabpanel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Sample Question Year - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                      <div className="coo zoom">
                          <a href="#" style={{ textDecoration: "none" }}>
                            <div className="row">
                              <div className="col-md-9 col-10">
                                <h2 className="text-left">
                                  Sample Question Year - 2010
                                </h2>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <p>Last updated on Mar 23, 2024</p>
                                  </div>

                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Questions </p>
                                  </div>
                                  {/* <div className='col-md-4'>
                                    <div className='aaaa'>
                                      <p className='aaa' style={{ fontWeight: "600" }}><span style={{ background: 'linear-gradient(136deg,#75cd25 2%,#25cd71 64%)', padding: '5px', color: '#fff', borderRadius: "5px" }}>dgdf</span></p>
                                    </div>
                                  </div> */}
                                  <div className='col-md-4'>
                                    <p className='aaa' style={{ fontWeight: "600" }}>100 Marks</p>
                                  </div>
                                  <div className='col-md-4'>
                                    <p style={{ fontWeight: "600" }}>60 Mins</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12">
                                <p className='btn btn-danger btn-block'> Start Now</p>
                              </div>

                            </div>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </section>














      <Footer />
    </>
  )
}