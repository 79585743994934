import Header from '../components/header';
import Footer from '../components/footer';

export default function Contact(){
  return(
    <>
      <Header />
      <img className='srimg' src="ccon.jpg" width="100%" style={{ borderRadius: 20 }} />
      <div className="contact">
        <div className="container">
          <div className="section-header text-center">
            <h2>Contact For Any Query</h2>
          </div>
          <div className="row align-items-center contact-information">
            <div className="col-md-6 col-lg-3">
              <div className="contact-info">
                <div className="contact-icon">
                  <i className="fa fa-map-marker-alt" />
                </div>
                <div className="contact-text">
                  <h3>Address</h3>
                  <p>
                  Meethapur Bypass Bhupattipur, Near Agriculture University, Patna, Bihar 800020
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="contact-info">
                <div className="contact-icon">
                  <i className="fa fa-phone-alt" />
                </div>
                <div className="contact-text">
                  <h3>Call Us</h3>
                  <p>0612-2201849/ +91- 9431421849</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="contact-info">
                <div className="contact-icon">
                  <i className="fa fa-envelope" />
                </div>
                <div className="contact-text">
                  <h3>Email Us</h3>
                  <p>info@pensbook.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="contact-info">
                <div className="contact-icon">
                  <i className="fa fa-share" />
                </div>
                <div className="contact-text">
                  <h3>Follow Us</h3>
                  <div className="contact-social">
                    <a href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="">
                      <i className="fab fa-youtube" />
                    </a>
                    <a href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row contact-form">
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3662.3683514557542!2d85.3305769759195!3d23.37488810308786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e112e74bbbe3%3A0x7b85012d16880072!2sHari%20Om%20Tower%2C%20lalpur%20road%2C%20near%20Ranchi%2C%20P%26T%20Colony%2C%20Lalpur%2C%20Ranchi%2C%20Jharkhand%20834001!5e0!3m2!1sen!2sin!4v1711620881074!5m2!1sen!2sin"
                width={"100%"}
                height={350}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="col-md-6">
              <div id="success" />
              <form name="sentMessage" id="contactForm" noValidate="novalidate">
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required="required"
                    data-validation-required-message="Please enter your name"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required="required"
                    data-validation-required-message="Please enter your email"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Subject"
                    required="required"
                    data-validation-required-message="Please enter a subject"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <textarea
                    className="form-control"
                    id="message"
                    placeholder="Message"
                    required="required"
                    data-validation-required-message="Please enter your message"
                    defaultValue={""}
                  />
                  <p className="help-block text-danger" />
                </div>
                <div>
                  <button
                    className="btn custom-btn"
                    style={{ background: "#69c9ff", border: "2px solid #69c9ff" }}
                    type="submit"
                    id="sendMessageButton"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}