import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components



const FirstSolution = () => {
    const { id } = useParams(0)
   
  var access_token='';
  const [AddFirstAttemptQuestionList,setAddFirstAttemptQuestionList] = useState([]);
  const api_url=process.env.REACT_APP_API_URL
 const navigate = useNavigate();
  useEffect(()=> {
    const token = localStorage.getItem('token');
    if (token) {
      firstAttemptques()
    } else {
      navigate('auth/login')
    }
    // console.log(AddFirstAttemptQuestionList.test_series)
  },[]);
  const printdata = async () => {
    window.print();
  }
  // const api_url=process.env.API_URL
  const firstAttemptques = async () => {
    const token = localStorage.getItem('token');
    const tokenWithBearer = "Bearer " + token;

    try {
        let result = await fetch(`${process.env.REACT_APP_BASE_URL}/user/get_first_attempt_submitted_test?exam_result_main_id=${id}`,{
            method: 'get',
            headers: {
                "Authorization": tokenWithBearer,
                // Other headers if needed
            }
        });
        const data = await result.json();
        console.log("Pankaj Mani Tiwari",data);
        setAddFirstAttemptQuestionList(data);
        // setLanguageName(data.language.name); // Uncomment this line if needed
    } catch (error) {
        console.error('Error fetching test section details:', error);
    }
};
  return (
    <>
      
      {/* Page content */}
      <Container className="mt-4" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                
                <Row>
                  <Col lg="6">
                  <h3 className="mb-0">First Attempt Answer List</h3>
                  </Col>
                  <Col lg="6" className="text-right">
                  <a className="btn btn-sm btn-success" onClick={printdata}>Print</a>
                   </Col>
                   </Row>
              </CardHeader>
              <CardBody>
              <Row>
              {AddFirstAttemptQuestionList?.questions && AddFirstAttemptQuestionList?.questions.length > 0 ? (
                AddFirstAttemptQuestionList.questions.map((item, index) => (
                  <React.Fragment key={index}>
                    <Col lg="1">
                      <h5>Que {index + 1}.</h5>
                    </Col>
                    <Col lg="11 mb-2">{item.question_id.question}</Col>
                    {/* //////Question */}
                    <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id.option_one && <span>a) {item.question_id.option_one}</span>}
                    </Col>
                    <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id.option_two && <span>b) {item.question_id.option_two}</span>}
                    </Col>
                    <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id.option_three && <span>c) {item.question_id.option_three}</span>}
                    </Col>
                    <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id.option_four && <span>d) {item.question_id.option_four}</span>}
                    </Col>
                     <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id &&  item.question_id?.option_five !== "N/A" && <span>e) {item.question_id.option_five}</span>}
                    </Col>
                     <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id && item.question_id && item.question_id?.option_six !== "N/A" && <span>f) {item.question_id.option_six}</span>}
                    </Col>
                    <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id && item.question_id.option_seven !== "N/A" || item.question_id.option_eight !== "" && <span>g) {item.question_id.option_seven}</span>}
                    </Col>
                     <Col lg="1 mb-1"></Col>
                    <Col lg="5 mb-1">
                      {item.question_id && item.question_id.option_eight !== "N/A"  && <span>h) {item.question_id.option_eight}</span>}
                    </Col>
                      <Col lg="1 mb-1"></Col>
                     <Col lg="5 mb-1">
                      {item.question_id && item.question_id.option_nine !== "N/A" && <span>i) {item.question_id.option_nine}</span>}
                    </Col>
                    <Col lg="1 mb-1"></Col>
                     <Col lg="5 mb-1">
                      {item.question_id && item.question_id.option_ten !== "N/A" && <span>j) {item.question_id.option_ten}</span>}
                    </Col>
                    {/* //////Answer */}
                    <Col lg="1 mb-1 mt-1"></Col>
                    <Col lg="11 mb-1"><b>Correct Answer :</b> {item.question_id.correct_answers[0]}</Col>
                    <Col lg="1 mb-1 mt-1"></Col>
                    <Col lg="11 mb-1"><b>Given Answer :</b> {item.selected_ans[0]}</Col>
                    {/* //////Solution */}
                    <Col lg="1 mb-1 "></Col>
                    <Col lg="11 mb-1"><b>Solution :</b> {item.question_id.suggestion}</Col>
                    
                    <hr />
                  </React.Fragment>
                ))
              ) : (
                'Loading'
              )}
            </Row>
              </CardBody>
              
              
             
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FirstSolution;