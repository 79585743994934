
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink ,useParams} from 'react-router-dom';    
import Header from '../components/header';
import Footer from '../components/footer';

export default function Exams() {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Fetch categories when the component mounts
    useEffect(() => {
        // const fetchCategories = async () => {
        //     try {
        //         //const response = await fetch(`http://192.168.0.102:1038/user/category`);
        //         const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/category`);
        //         if (!response.ok) {
        //             throw new Error('Failed to fetch categories');
        //         }
        //         const data = await response.json();
        //         // Update the state with fetched categories
        //         setCategories(data.category);

        //         // Set the selected category to the ID of the first category
        //         if (data.category.length > 0) {
        //             setSelectedCategory(data.category[0]._id);
        //             fetchSubCategories(data.category[0]._id);
        //         }
        //     } catch (error) {
        //         console.error(error);
        //         // Handle errors, e.g., display an error message
        //     }
        // };

        fetchSubCategories();
    }, []);
    const fetchSubCategories = async (selectedCategory) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/sub_category`);
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();
            setSubCategories(data.sub_category);
            console.log("Pankaj mani",subCategories)
        } catch (error) {
            console.error(error);
            // Handle errors, e.g., display an error message
        }
    };
  return (
    <>
      <Header />
      <img className='srimg' src="exams.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section
  className="dd"
  style={{
    background: "url(dfddfvbds.png)",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <h3 style={{ color: "#2e0d78" }}>Exams We Conduct</h3>
    <hr style={{ border: "1px solid red" }} />
    <div className="row">



        { subCategories?.length>0 ?(
            subCategories?.map((item,index) =>(
                <div className="col-md-4 col-12">
                <div className="item">
                  <div className="card"  style={{
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    borderBottom: '10px solid #007bff !important'
  }}>
                    <img  src={`${process.env.REACT_APP_BASE_URL}/${item.image}`} height={"200px"} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h6 className="card-title text-center">
                      {item.name}
                      </h6>
                      {/* <p class="card-text">Test Series is equipped with the team of top most faculties for preparation Class - 6 Exams</p> */}
                      <center>
                        {/* <a href={`/testseries_details/${item._id}`} className="btn btn-primary"> */}
                        <a href={`/testserieslist/${item._id}`} className="btn btn-primary">
                          Visit Series
                        </a>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

            ))
        ) : (
            'Loading'
          )}

     



      
    </div>
  </div>
</section>

      <Footer />
    </>
  )
}