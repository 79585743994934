import Header from '../components/header';
import Footer from '../components/footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink ,useParams} from 'react-router-dom';
import { Form } from 'reactstrap';
export default function Profile() {
    
    const [profile, setProfile] = React.useState([]);
    const [name, setName] = React.useState();
    const [email, setEmail] = React.useState();
    const [profile_image, setProfile_image] = React.useState();
    const [full_address, setFull_address] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    // const { id } = useParams();
    useEffect(() => {
        const fetchprofile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/profile`, {
                    method:"get",
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                console.log(response.data);
                
                setProfile(response.data);
                setIsLoading(false);
            } catch (error) {
               
                setIsLoading(false);
            }
        };

        fetchprofile();
    }, []);
    const updateprofile =  async () => {
        const data = "";
        const authToken = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('name',name);
        formData.append('email',email);
        formData.append('image',profile_image);
        formData.append('full_address',full_address);
        
       

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/update_profile`, {
            method:"post",
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            body: formData,
        });
        if(response.ok){
          alert("Profile Updated");
          window.location.href = '/profile';
        }
      
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }
  return (
    <>
      <Header />
      <img className='srimg' src="../profiles.jpg" width="100%" style={{ borderRadius: 20 }} />
      <section
  className="dd"
  style={{
    background: "url(dfddfvbds.png)",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <h2 style={{ color: "#2e0d78" }}>PROFILE</h2>
    <hr style={{ border: "1px solid red" }} />
    <div className="row">
        <div class="col-md-6"> 
            <div className='row'>
            <div className='col-md-6 text-bold'><h4>Profile Details</h4></div>
                <div className='col-md-12 text-bold mb-2'><img src={`${process.env.REACT_APP_BASE_URL}/${profile?.user.profile_image}`} style={{height:"200px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}} className="img-fluid img-circle elevation-2" alt="..." /></div>
                <div className='col-md-6 text-bold mb-2'>Name :</div>
                <div className='col-md-6 mb-2'>{profile?.user.name}</div>
                <div className='col-md-6 text-bold mb-2'>Email :</div>
                <div className='col-md-6 mb-2'>{profile?.user.email}</div>
                <div className='col-md-6 text-bold mb-2'>Address :</div>
                <div className='col-md-6 mb-2'>{profile?.user.address}</div> 
                
            </div>
        


        
        
        
        </div>
        <div class="col-md-6">
            <div className='col-md-12 text-bold'><h4>Update Profile</h4></div>
            <form>
                <div className="control-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required="required"
                    data-validation-required-message="Please enter your name"
                    onChange={(e) => {
                        setName(e.target.value)
                     
                       
                        
                    }}
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required="required"
                    onChange={(e) => {
                        setEmail(e.target.value)
                     
                       
                        
                    }}
                    data-validation-required-message="Please enter your email"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <input
                    type="file"
                    className="form-control"
                    id="profile_image"
                    placeholder="profile_image"
                    required="required"
                    onChange={(e) => {
                        setProfile_image(e.target.files[0]);
                    }}
                    data-validation-required-message="Please enter a subject"
                  />
                  <p className="help-block text-danger" />
                </div>
                <div className="control-group">
                  <textarea
                    className="form-control"
                    id="full_address"
                    placeholder="Full Address"
                    required="required"
                    onChange={(e) => {
                        setFull_address(e.target.value);
                    }}
                    data-validation-required-message="Please enter your message"
                    defaultValue={""}
                  />
                  <p className="help-block text-danger" />
                </div>
                <div>
                  <button
                    className="btn custom-btn"
                    style={{ background: "#69c9ff", border: "2px solid #69c9ff" }}
                    type="button"
                    id="sendMessageButton"
                    onClick={updateprofile}
                  >
                    Submit
                  </button>
                </div>
              </form>
        </div>
        
    </div>
  </div>
</section>

      <Footer />
    </>
  )
}