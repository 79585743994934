import Header from '../header';
import Footer from '../footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';


// import TestStructureDetails from './examStructureDetails';
import PackageStructure from './packageStructure';

const SeriesDetails = () => {

    const [testSeries, setTestSeries] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
  
    // const [catId, setCatId] = useState(null);
    // const [subcatId, setSubCatId] = useState(null);

    // Get the test_series_id from the URL
    


    // if (isLoading) {
    //     return <p>Loading...</p>;
    // }

    // if (error) {
    //     return <p>Error: {error}</p>;
    // }

    return (
        <>
            <Header />
            
            <section class="newss">
            <div className="row">
                         
            </div>
                <PackageStructure/>
            </section>
            <Footer />
        </>
    );
};

export default SeriesDetails;
