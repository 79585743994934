import Header from '../components/header';
import Footer from '../components/footer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import { NavLink ,useParams} from 'react-router-dom';
export default function Resume() {
    
    const [testSeries, setTestSeries] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [fname, setFname] = React.useState(null);
    const [dob, setDob] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [mobile, setMobile] = React.useState(null);
    const [link, setLink] = React.useState(null);
    const [alternetMobile, setAlternetMobile] = React.useState(null);
    const [nationality, setNationality] = React.useState(null);
    const [religion, setReligion] = React.useState(null);
    const [sex, setSex] = React.useState(null);
    const [maritalstatus, setMaritalStatus] = React.useState(null);
    const [permanentAddress, setPermanentAddress] = React.useState(null);
    const [qualificationSummary, setQualificationSummary] = useState([{ id: 0, value: '' }]);
    const [qualificationSummaryList, setQualificationSummaryList] = useState([]);
    const [qualificationList, setQualificationList] = useState(''); // State variable for input field value
    const [curricularActivitiesList, setCurricularActivitiesList] = useState(''); // State variable for input field value
    const [workExprienceList, setWorkExprienceList] = useState(''); // State variable for input field value
    const [keyCompetenceList, setKeyCompetenceList] = useState(''); // State variable for input field value
    const [languageList, setLanguageList] = useState(''); // State variable for input field value
   
    // const [educationalSummary, setEducationalSummary] = useState([{ id: 0, value: '' }]);
    const [curricularActivities, setCurricularActivities] = useState([{ id: 0, value: '' }]);
    const [workExprience, setWorkExprience] = useState([{ id: 0, value: '' }]);
    const [keyCompetencies, setKeyCompetencies] = useState([{ id: 0, value: '' }]);
    const [language, setLanguage] = useState([{ id: 0, value: '' }]);
    const [hobbies, setHobbies] = React.useState(null);
    const { subcategory_id } = useParams();
    const [inputType, setInputType] = useState('text');

    const handleFocus = () => {
        setInputType('date');
    };

    const handleBlur = () => {
        setInputType('text');
    };
  
    
  
    useEffect(() => {
        const fetchTestSeries = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/test_series?type=all`, {
                    headers: {
                        // 'Authorization': `Bearer ${authToken}`
                    }
                });
                console.log(response.data);
                
                setTestSeries(response.data);
                setIsLoading(false);
            } catch (error) {
               
                setIsLoading(false);
            }
        };

        fetchTestSeries();
    }, [subcategory_id]);


    const [educationalSummary, setEducationalSummary] = useState([{ id: 0, nameOfExamination: '', grade: '', schoolName: '', year: '', percentage: '', subject: '' }]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const list = [...educationalSummary];
        list[index][name] = value;
        setEducationalSummary(list);
        
    };

    const handleAddInputEducation = () => {
        setEducationalSummary([...educationalSummary, { id: educationalSummary.length, nameOfExamination: '', grade: '', schoolName: '', year: '', percentage: '', subject: '' }]);
    };

    const handleRemoveInputEducation = (index) => {
        const list = [...educationalSummary];
        list.splice(index, 1);
        setEducationalSummary(list);
    };



    function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
          });
        }
        return result;
      }

      
    const handleInputQualification = (index, value) => {
        setQualificationList((prevList) => {
            const newList = [...prevList]; // Make a copy of the previous list
            newList[index] = value; // Update the value at the specified index
            return newList; // Return the updated list
        });
    };

    const handleInputCurricular = (index, value) => {
        setCurricularActivitiesList((prevList) => {
            const newListCurricular = [...prevList]; // Make a copy of the previous list
            newListCurricular[index] = value; // Update the value at the specified index
            return newListCurricular; // Return the updated list
        });
    };

    const handleInputWorkExprience = (index, value) => {
        setWorkExprienceList((prevList) => {
            const newListWorkExprience = [...prevList]; // Make a copy of the previous list
            newListWorkExprience[index] = value; // Update the value at the specified index
            return newListWorkExprience; // Return the updated list
        });
    };

      const handleInputCompetenceList = (index, value) => {
        setKeyCompetenceList((prevList) => {
            const newListCompetenceList = [...prevList]; // Make a copy of the previous list
            newListCompetenceList[index] = value; // Update the value at the specified index
            return newListCompetenceList; // Return the updated list
        });
    };

    const handleInputLanguageList = (index, value) => {
        setLanguageList((prevList) => {
            const newLanguageList = [...prevList]; // Make a copy of the previous list
            newLanguageList[index] = value; // Update the value at the specified index
            
            return newLanguageList; // Return the updated list
        });
    };

    

    

    const handleAddInput = () => {
        setQualificationSummary([...qualificationSummary, { id: qualificationSummary.length, value: '' }]);
      };

      const handleAddInputeducation = () => {
        setEducationalSummary([...educationalSummary, { id: educationalSummary.length, value: '' }]);
      };

      const handleAddInputcurricular = () => {
        setCurricularActivities([...curricularActivities, { id: curricularActivities.length, value: '' }]);
      };

      const handleAddInputexprience = () => {
        setWorkExprience([...workExprience, { id: workExprience.length, value: '' }]);
      };

      const handleAddInputcompetencies = () => {
        setKeyCompetencies([...keyCompetencies, { id: keyCompetencies.length, value: '' }]);
      };

      const handleAddInputlanguage = () => {
        setLanguage([...language, { id: language.length, value: '' }]);
      };

      const handleRemoveInputkeyLanguage = (index) => {
        const newList_Language= [...language];
        newList_Language.splice(index, 1);
        setLanguage(newList_Language);
      };

      const handleRemoveInputkeyCompetencies = (index) => {
        const newList_Competencies= [...keyCompetencies];
        newList_Competencies.splice(index, 1);
        setKeyCompetencies(newList_Competencies);
      };
      
      const handleRemoveInputworkExprience = (index) => {
        const newList_exprience= [...workExprience];
        newList_exprience.splice(index, 1);
        setWorkExprience(newList_exprience);
      };
      

      const handleRemoveInputcurricularactivities = (index) => {
        const newList_curricular= [...curricularActivities];
        newList_curricular.splice(index, 1);
        setCurricularActivities(newList_curricular);
      };

      const handleRemoveInputeducation = (index) => {
        const newList_data = [...educationalSummary];
        newList_data.splice(index, 1);
        setEducationalSummary(newList_data);
      };



      const handleRemoveInput = (index) => {
        const newList = [...qualificationSummary];
        newList.splice(index, 1);
        setQualificationSummary(newList);
      };

      var generateData = function(amount) {
        var result = [];
        
        for (var i = 0; i < educationalSummary.length; i += 1) {
            var data = {
                Edu_Name: educationalSummary[i]['nameOfExamination'],
                BU: educationalSummary[i]['grade'],
                College_Name: educationalSummary[i]['schoolName'],
                Year: educationalSummary[i]['year'],
                Persentage: educationalSummary[i]['percentage'],
                Subject: educationalSummary[i]['subject']
              };
          data.SL = (i + 1).toString();
          result.push(Object.assign({}, data));
        }
        return result;
      };
      
      function createHeaders(keys) {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            // width: 20,
            align: "center",
            padding: 0,
            
          });
        }
        return result;
      }

      const resumeGenerate=()=>{
        alert(qualificationList[0])
        // console.log("I want To test it",);

        // for (let index = 0; index < qualificationList.length; index++) {
        //     const element = qualificationList[index];
        //     alert(element);
            
        // }


        const doc = new jsPDF();
        doc.setLineWidth(1)
        doc.rect(10, 10, 190, 275);
        doc.setLineWidth(0)
        doc.rect(8, 8, 194, 279);

        doc.setFontSize(21);
        doc.text("RESUME", 82, 20);
        const textX = 82; // X-coordinate of the text
        const textY = 0.2; // Y-coordinate of the text
        const textWidth = doc.getStringUnitWidth("RESUME") * doc.internal.getFontSize() / doc.internal.scaleFactor; // Width of the text
        
        // Calculate the positions for the underline
        const underlineY = textY + 1.0 * doc.internal.getFontSize(); // Position the underline below the text
        const underlineXStart = textX - 1; // Start X-coordinate of the underline
        const underlineXEnd = textX + textWidth + 1; // End X-coordinate of the underline
       
        // Set the line width for the underline
        doc.setLineWidth(0.5); // Adjust the thickness of the underline as needed
        
        // Draw the underline
        doc.line(underlineXStart, underlineY, underlineXEnd, underlineY);
        doc.setFontSize(19);
        
        doc.text(name, 13, 40);

       
        doc.setFontSize(10);
        doc.text(permanentAddress, 13, 50);
        doc.text(mobile, 13, 55);
        doc.text(",",33,55);
        doc.text(alternetMobile, 35, 55);
        doc.setFont("helvetica", "bold");
        doc.text("Email:", 13, 60);
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text(email, 25, 60);
        doc.setFont("helvetica", "bold");
        doc.text("Link:", 13, 65);
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text(link, 25, 65);

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Objective :", 13, 78);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold"); 
        doc.text("To secure a job in best sector where I can expertise and utilize my skills& ability to the fast development of", 13, 85);
        doc.text("company and I can get opportunities through which I can contribute to the company’s profit.", 13, 90);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("QUALIFICATION SUMMARY", 78, 100);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, 103, 198, 103);
        const sizerecStart = 111; // Starting position for sizerec
        let sizerec = sizerecStart; // Initialize sizerec with its starting position

        for (let index = 0; index < qualificationList.length; index++) {
            const element = qualificationList[index];
            
            doc.circle(18, sizerec, 1, "F"); // Draw circle at fixed x-coordinate (18) and variable y-coordinate (sizerec)
            doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
            doc.text(element, 23, sizerec + 1); // Draw text after circle
            sizerec += 5; // Increment sizerec for next iteration
        }

       


        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("EDUCATION DETAILS", 78, sizerec+5);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, sizerec+7, 198, sizerec+7);
      
        doc.setFontSize(8);
          doc.setFont("helvetica", "normal");
        var headers = createHeaders([
            "SL",
            "Edu_Name",
            "BU",
            "College_Name",
            "Year",
            "Persentage",
            "Subject"
          ]);
          doc.setLineWidth(0)
         const exampt = educationalSummary.length;
         
        //   doc.table(18,132, generateData(2), headers, { autoSize: true });
        doc.setFontSize(10); // Set your desired font size
        doc.setFontSize(2);
        doc.table(18, sizerec+12, generateData(exampt), headers, { 
        autoSize: true
        });

       const sizelow =sizerec+(12*exampt)+(8*exampt);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("EXTRA-CURRICULAR ACTIVITIES ", 70, sizelow+20);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, sizelow+16, 198, sizelow+16);
        const newsize = sizelow+23;
        let sizecaric = newsize;
        for (let index = 0; index < curricularActivitiesList.length; index++) {
            const elements = curricularActivitiesList[index];
            
            doc.circle(18,sizecaric, 1, "F"); // Draw circle at fixed x-coordinate (18) and variable y-coordinate (sizerec)
            doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
            doc.text(elements, 23, sizecaric + 1); // Draw text after circle
            sizecaric += 5; // Increment sizerec for next iteration
        }
        // doc.circle(18, 208, 1, "F");
        // doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        // doc.text("Participated in school & college level Modelling/Ramp walk debate and got 3rd prize and .", 23, 210);
        // doc.text("applauds", 23, 215);
       
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("WORK EXPRIENCE ", 78, sizecaric+5);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, sizecaric+10, 198, sizecaric+10);
         
        const newsizees = sizecaric+8;
        let oldrenewsize = newsizees+5;
        for (let index = 0; index < workExprienceList.length; index++) {
            const elementsss= workExprienceList[index];
            doc.circle(18,oldrenewsize, 1, "F"); // Draw circle at fixed x-coordinate (18) and variable y-coordinate (sizerec)
            doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
            doc.text(elementsss, 23, oldrenewsize + 1); // Draw text after circle
            oldrenewsize += 5; // Increment sizerec for next iteration
        }

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("KEY COMPETENCIES ", 78, oldrenewsize+7);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, oldrenewsize+12, 198, oldrenewsize+12);
        const newsizeess = oldrenewsize+12;
        let oldrenewsizerecs = newsizeess+12;

        for (let index = 0; index < keyCompetenceList.length; index++) {
            const elementssss= keyCompetenceList[index];
            doc.circle(18,oldrenewsizerecs, 1, "F"); // Draw circle at fixed x-coordinate (18) and variable y-coordinate (sizerec)
            doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
            doc.text(elementssss, 23, oldrenewsizerecs + 1); // Draw text after circle
            oldrenewsizerecs += 5; // Increment sizerec for next iteration
        }

     
        doc.addPage();
        doc.setLineWidth(1)
        doc.rect(10, 10, 190, 275);
        doc.setLineWidth(0)
        doc.rect(8, 8, 194, 279);
        

        
        

        


        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("LANGUAGE & COMPUTATION ", 78, 15);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12,6, 198,6);
        var oldrenewsizerecss = 20 + 4; // Corrected variable name
        for (let index = 0; index < languageList.length; index++) {
            const element = languageList[index]; // Corrected variable name
            doc.circle(18, oldrenewsizerecss, 1, "F"); // Draw circle at fixed x-coordinate (18) and variable y-coordinate (oldrenewsizerecss)
            doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
            doc.text(element, 23, oldrenewsizerecss + 1); // Draw text after circle
            oldrenewsizerecss += 5; // Increment oldrenewsizerecss for next iteration
        }


        // doc.circle(18, 88, 1, "F");
        // doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        // doc.text("Proficient in written & spoken English and Hindi. ", 23, 90);

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("PERSONAL DETAIL", 78, oldrenewsizerecss+7);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, oldrenewsizerecss+12, 198, oldrenewsizerecss+12);
        const ctsize = oldrenewsizerecss+12;
        doc.circle(18, ctsize+7, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Name", 23, ctsize+7);
        doc.text(name,80, ctsize+7);

        doc.circle(18, ctsize+14, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Father’s Name", 23, ctsize+14);
        doc.text(fname,80, ctsize+14);

        doc.circle(18, ctsize+21, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("D.O.B", 23, ctsize+21);
        doc.text(dob,80, ctsize+21);

        doc.circle(18, ctsize+28, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Nationality", 23, ctsize+28);
        doc.text(nationality,80, ctsize+28);

        doc.circle(18, ctsize+35, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Religion", 23, ctsize+35);
        doc.text(religion,80, ctsize+35);

        doc.circle(18, ctsize+42, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Sex", 23, ctsize+42);
        doc.text(sex,80, ctsize+42);

        doc.circle(18, ctsize+49, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Marital Status", 23, ctsize+49);
        doc.text(maritalstatus,80, ctsize+49);

        doc.circle(18, ctsize+56, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Permanent Address", 23, ctsize+56);
        doc.text([permanentAddress],80, ctsize+56);

        doc.circle(18, ctsize+63, 1, "F");
        doc.setFont("helvetica", "normal"); // Resetting font style to normal for subsequent text
        doc.text("Hobbies", 23, ctsize+63);
        doc.text(hobbies,80, ctsize+63);

        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        // doc.setLineDash([2.5]);
        doc.line(12, 175, 198, 175);

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Declaration :-", 13, 188);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold"); 
        doc.text("The information furnished so far is the best of my knowledge and bear the responsibility of characterness", 13, 193);
        doc.text(" of the above mentioned particular.", 13, 198);

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");

        doc.text("NAME", 13, 215);
        doc.text("SIGNATURE", 172, 215);




        // ''''''''''''''''''''''''''''''''PDF GENERATE"""""""""""""""""""""""""""""""""""""""
        const pdfContent = doc.output('datauristring');
        const byteCharacters = atob(pdfContent.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            newWindow.focus();
        } else {
            alert('Please allow popups for this website to view the PDF.');
        }

        // doc.output('datauristring');
        // doc.save('generated_resume.pdf');
      }




    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }
  return (
    <>
      <Header />
      {/* <img className='srimg' src="ts.jpg" width="100%" style={{ borderRadius: 20 }} /> */}
      <section
  className="dd"
  style={{
    background: "url(dfddfvbds.png)",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <h3 style={{ color: "#2e0d78" }}>Create Resume</h3>
    <hr style={{ border: "1px solid red" }} />
    <div className="row">
          <div className='col-md-12'>
          <div className="item">
            <div className="card">
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Personal Information</h5><hr style={{border:"3px solid rgb(121, 99, 210)"}}/></div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Your Name"
                                required="required"
                                onChange={(e) => {
                                    setName(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Father's Name"
                                required="required"
                                onChange={(e) => {
                                    setFname(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-6'> 
                        <input
                            placeholder="Enter Your Date Of Birth"
                            className="textbox-n form-control"
                            type={inputType}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                             onChange={(e) => {
                                    setDob(e.target.value)  
                                }}
                            id="date"
                            />
                            </div>
                            <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Email"
                                required="required"
                                onChange={(e) => {
                                    setEmail(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                         <div className='col-md-4'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Mobile"
                                required="required"
                                onChange={(e) => {
                                    setMobile(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-4'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Alternet Mobile"
                                required="required"
                                onChange={(e) => {
                                    setAlternetMobile(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your mobiless"
                            />
                        </div>
                        <div className='col-md-4'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Link"
                                required="required"
                                onChange={(e) => {
                                    setLink(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your mobiless"
                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Nationality"
                                required="required"
                                onChange={(e) => {
                                    setNationality(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your nationality"
                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Religion"
                                required="required"
                                onChange={(e) => {
                                    setReligion(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Sex"
                                required="required"
                                onChange={(e) => {
                                    setSex(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Marital Status"
                                required="required"
                                onChange={(e) => {
                                    setMaritalStatus(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                       
                        <div className='col-md-6'>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder="Enter Your Hobbies"
                                required="required"
                                onChange={(e) => {
                                    setHobbies(e.target.value)  
                                }}
                                data-validation-required-message="Please enter your name"
                            />
                        </div>
                        <div className='col-md-6'>
                            {/* <input
                                type="text"
                                className="form-control mb-2"
                                id="fname"
                                placeholder=""
                                required="required"
                                
                                data-validation-required-message="Please enter your name"
                            /> */}
                             <textarea
                                className="form-control"
                                id="message"
                                placeholder="Enter Your Permanent Address"
                                required="required"
                                onChange={(e) => {
                                    setPermanentAddress(e.target.value)  
                                }}
                                data-validation-required-message="Enter Your Permanent Address"
                                defaultValue={""}
                            />
                        </div>
                        <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Qualification Summary</h5>
                        <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div>
                         <div className='col-md-12'>
                                {/* {qualificationSummary.map((input, index) => (
                                    <div className='row' key={index}>   
                                        <div className='col-md-11'>
                                            <input
                                                type="text"
                                               
                                                className="form-control mb-2"
                                                id="qualification_summary"
                                                placeholder="Enter Your Qualification Summary"
                                                required="required"
                                                value={qualificationList} // Bind the input field value to the state variable
                                                onChange={handleInputChange} 
                                                data-validation-required-message="Please enter your name"
                                            />
                                        </div> 
                                        {index === 0 ? (
                                            <div className="col-md-1">
                                                <button type="button" onClick={handleAddInput} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                            </div>
                                        ) : (
                                            <div className="col-md-1">
                                                <button type="button" onClick={() => handleRemoveInput(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                            </div>
                                        )}   
                                    </div>
                                ))} */}
                                {qualificationSummary.map((input, index) => (
                                    <div className='row' key={index}>   
                                        <div className='col-md-11'>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id={`qualification_summary_${index}`}
                                                name="qualification_summary" // Use the same name for all input fields
                                                placeholder="Enter Your Qualification Summary"
                                                required="required"
                                                value={qualificationList[index] || ''}
                                                onChange={(e) => handleInputQualification(index, e.target.value)} 
                                                data-validation-required-message="Please enter your name"
                                            />
                                        </div> 
                                        {index === 0 ? (
                                            <div className="col-md-1">
                                                <button type="button" onClick={handleAddInput} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                            </div>
                                        ) : (
                                            <div className="col-md-1">
                                                <button type="button" onClick={() => handleRemoveInput(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                            </div>
                                        )}   
                                    </div>
                                ))}
                            </div> 
                         <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Education Details</h5>
                        <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div> 
                        <div className='col-md-12'>
                        {educationalSummary.map((input, index) => (
                <div className='row' key={index}>
                    <div className='col-md-3'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Name Of Examination"
                            name="nameOfExamination"
                            value={input.nameOfExamination}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div className='col-md-2'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="B/U"
                            name="grade"
                            value={input.grade}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div className='col-md-2'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Collage/School Name"
                            name="schoolName"
                            value={input.schoolName}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div className='col-md-1'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Year"
                            name="year"
                            value={input.year}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div className='col-md-1'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="%"
                            name="percentage"
                            value={input.percentage}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    <div className='col-md-2'>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Subject"
                            name="subject"
                            value={input.subject}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                    </div>
                    {index === 0 ? (
                        <div className="col-md-1">
                            <button type="button" onClick={handleAddInputEducation} className="btn btn-success"><i className="fal fa-plus"></i></button>
                        </div>
                    ) : (
                        <div className="col-md-1">
                            <button type="button" onClick={() => handleRemoveInputEducation(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                        </div>
                    )}
                </div>
            ))}
                        </div>                   
                        <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Extra Curricular Activities</h5>
                        <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div>   
                        <div className='col-md-12'>
                            {curricularActivities.map((input, index) => (
                                <div className='row' key={index}>   
                                    <div className='col-md-11'>
                                    
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                              id={`caricular_activities_${index}`}
                                            placeholder="Enter Your Curricular Activities"
                                            required="required"
                                            value={curricularActivitiesList[index]|| ''}
                                            onChange={(e) => {
                                                handleInputCurricular(index,e.target.value)  
                                            }}
                                            data-validation-required-message="Please enter your name"
                                        />
                                    </div> 
                                    {index === 0 ? (
                                        <div className="col-md-1">
                                            <button type="button" onClick={handleAddInputcurricular} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                        </div>
                                    ) : (
                                        <div className="col-md-1">
                                            <button type="button" onClick={() => handleRemoveInputcurricularactivities(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                        </div>
                                    )}   
                                </div>
                            ))}
                        </div>  
                         <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Work Experience</h5>
                             <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div>  
                        <div className='col-md-12'>
                            {workExprience.map((input, index) => (
                                <div className='row' key={index}>   
                                    <div className='col-md-11'>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            id={`work_exprience_${index}`}
                                            placeholder="Enter Your Work Experience"
                                            required="required"
                                             value={workExprienceList[index]|| ''}
                                            onChange={(e) => {
                                                handleInputWorkExprience(index,e.target.value)  
                                            }}
                                            data-validation-required-message="Please enter your name"
                                        />

      




                                    </div> 
                                    {index === 0 ? (
                                        <div className="col-md-1">
                                            <button type="button" onClick={handleAddInputexprience} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                        </div>
                                    ) : (
                                        <div className="col-md-1">
                                            <button type="button" onClick={() => handleRemoveInputworkExprience(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                        </div>
                                    )}   
                                </div>
                            ))}
                        </div>  
                        <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Key Competencies</h5>
                             <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div>   
                        <div className='col-md-12'>
                            {keyCompetencies.map((input, index) => (
                                <div className='row' key={index}>   
                                    <div className='col-md-11'>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            id={`key_Competencies_${index}`}
                                            placeholder="Enter Your Key Competencies"
                                            required="required"
                                            value={keyCompetenceList[index]|| ''}
                                            onChange={(e) => {
                                                handleInputCompetenceList(index,e.target.value)  
                                            }}
                                            data-validation-required-message="Please enter your name"
                                        />
                                    </div> 
                                    {index === 0 ? (
                                        <div className="col-md-1">
                                            <button type="button" onClick={handleAddInputcompetencies} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                        </div>
                                    ) : (
                                        <div className="col-md-1">
                                            <button type="button" onClick={() => handleRemoveInputkeyCompetencies(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                        </div>
                                    )}   
                                </div>
                            ))}
                        </div>  
                        <div className='col-md-12'><h5 style={{ color: "#2e0d78" }}>Language & Computation</h5>
                             <hr style={{border:"3px solid rgb(121, 99, 210)"}}/>
                        </div> 

                        <div className='col-md-12'>
                            {language.map((input, index) => (
                                <div className='row' key={index}>   
                                    <div className='col-md-11'>
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            id={`language_${index}`}
                                            placeholder="Language & Computation"
                                            required="required"
                                            value={languageList[index]|| ''}
                                            onChange={(e) => {
                                                handleInputLanguageList(index,e.target.value)  
                                            }}
                                            data-validation-required-message="Please enter your Languaege"
                                        />
                                    </div> 
                                    {index === 0 ? (
                                        <div className="col-md-1">
                                            <button type="button" onClick={handleAddInputlanguage} className="btn btn-success"><i className="fal fa-plus"></i></button>
                                        </div>
                                    ) : (
                                        <div className="col-md-1">
                                            <button type="button" onClick={() => handleRemoveInputkeyLanguage(index)} className="btn btn-danger"><i className="fa fa-minus"></i></button>
                                        </div>
                                    )}   
                                </div>
                            ))}
                        </div> 
                        <div className='col-md-4 mt-3 text-center'></div>
                        <div className='col-md-4 mt-3 text-center'>
                            {/*  */}
                           
                           <button type="button" onClick={resumeGenerate}   className='btn btn-xl form-control'    style={{background: "linear-gradient(58deg, #e45b0d, #8e277f, #48b8a0)",color:"#fff"}}>Submit</button>
                            
                        </div> 
                        <div className='col-md-4 mt-3 text-center'></div>    




                    </div>     
                </div>
            </div>
            </div>
          </div>
      
    </div>
  </div>
</section>

      <Footer />
    </>
  )
}