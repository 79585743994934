
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useParams,  Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function ExamStructure() {
    const navigate = useNavigate();
    const [examStructure, setExamStructure] = useState([]);
    const [packageCategory, setPackageCategory] = useState([]);
    const [packageSubCategory, setPackageSubCategory] = useState([]);
    const [packageAll, setPackageAll] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState([]);
    const [testSeries, setTestSeries] = useState(null);
    const [structureId, setStructureId] = useState(null);
    const { catId } = useParams(0);
    const { subcatId } = useParams(1);
    const { test_series_id } = useParams(2);
    useEffect(() => {
        const authToken = localStorage.getItem('token');
        if (authToken) {
            const fetchCategoryPackage = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/price`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        },
                        params: {
                            product_id: catId,
                        }
                    });
                    console.log("Mohan", response);
                    setPackageCategory(response.data.price)
                } catch (error) {
                    console.error('Error fetching category package', error);
                }
            };
            const fetchSubCategoryPackage = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/price`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        },
                        params: {
                            product_id: subcatId,
                        }
                    });
                    console.log("Mohan2", response);
                    setPackageSubCategory(response.data.price)
                } catch (error) {
                    console.error('Error fetching category package', error);
                }
            };

            const fetchAllPackage = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/price`, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        },
                        params: {
                            type: "all",
                        }
                        
                       
                    });
                    console.log("Mohan3", response);
                    setPackageAll(response.data)
                } catch (error) {
                    console.error('Error fetching category package', error);
                }
            };
            fetchCategoryPackage();
            fetchSubCategoryPackage();
            fetchAllPackage();
        } else {
            navigate('/login');
        }
    }, [catId,subcatId, navigate]);

    

   
   

    const handleItemClick = async (itemId) => {
        try {
            const authToken = localStorage.getItem('token');
            if(authToken!=null){
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                      'Authorization': `Bearer ${authToken}`
                  }
              });
              setSelectedItemDetails(response.data);
              console.log(selectedItemDetails);

            }else{
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series_without_login?exam_structure_id=${itemId}&test_series_id=${test_series_id}`, {
                  headers: {
                    'Authorization': ''
                }
              });
              console.log("RAHUL",response.data);
              setSelectedItemDetails(response.data);
                            
             

            }
          
           
        } catch (error) {
          setSelectedItemDetails([]);
            console.error('Error fetching test series:', error.message);
        }
    };
  return (
    <>
      {/* <section className='t'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
           
            </div>
        </div>
        </div> 
      </section> */}

      <section className="xs">
        <div className="container">
          <div className="row t">
            <div className="col-md-12">
            <h3 className="text-danger text-uppercase">Package For All Tests</h3>
             
            </div>
            {packageAll && packageAll.price?.map((item, index) => (
                 <div className="col-md-12 bg-info mb-2 p-3">
                    <div className="row text-light">
                        <div className='col-md-3'><h5>{item.title}</h5></div>
                        <div className='col-md-3'><span>{item.price}/-</span></div>
                        <div className='col-md-3'><span className='text-right'>{item.duration} Month</span></div>
                        <div className='col-md-3'><a href={`/coupan_details/${test_series_id}/${item._id}`} className='btn btn-sm btn-warning text-right' >Purchase</a></div>
                    </div>
                 </div>
                 
            ))}
           <div className="col-md-12"><hr/></div>
            <div className="col-md-12">
               <h3 className="text-danger text-uppercase">Package For Category Test</h3>
            </div>
            {packageCategory && packageCategory.price?.map((item, index) => (
                 <div className="col-md-12 bg-info mb-2 p-3">
                    <div className="row text-light">
                        <div className='col-md-3'><h5>{item.title}</h5></div>
                        <div className='col-md-3'><span>{item.price}/-</span></div>
                        <div className='col-md-3'><span className='text-right'>{item.duration} Month</span></div>
                        <div className='col-md-3'><a href={"coupan_details?test_series_id="+test_series_id+"&price_id="+item._id} className='btn btn-sm btn-warning text-right' >Purchase</a></div>
                    </div>
                 </div>
                 
            ))}
            <div className="col-md-12"><hr/></div>
            <div className="col-md-12">
               <h3 className="text-danger text-uppercase">Package For Sub Category Test</h3>
            </div>
            {packageSubCategory && packageSubCategory.price?.map((item, index) => (
                 <div className="col-md-12 bg-info mb-2 p-3">
                    <div className="row text-light">
                        <div className='col-md-3'><h5>{item.title}</h5></div>
                        <div className='col-md-3'><span>{item.price}/-</span></div>
                        <div className='col-md-3'><span className='text-right'>{item.duration} Month</span></div>
                        <div className='col-md-3'><a href={"coupan_details?test_series_id="+test_series_id+"&price_id="+item._id} className='btn btn-sm btn-warning text-right' >Purchase</a></div>
                    </div>
                 </div>
                 
            ))}
          </div>
        </div>
      </section>

    </>
  )
}